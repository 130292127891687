import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';

import { getValuesFromArrayOfObjects } from '../../../../helpers/getValuesFromArrayOfObjects';
import { FleetTireRecap } from '../../../../types/fleet';
import { Article } from '../components/Article';
import { Header } from '../components/Header';

interface IProps {
  data?: FleetTireRecap;
}

function getCellValue(data: string) {
  return data ?? 'Not included';
}

export function TireRecap({ data }: IProps) {
  const { t } = useTranslation();

  return (
    <Article>
      <div className="table-container">
        <Table celled unstackable compact>
          <Table.Header colspan="5">
            <Table.Row>
              <Table.Cell className="title" colspan="7">
                <Header icon="wrench" content="Tire recap" />
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {t('page.fleets.label.tireRecap.pullPointRetread')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="6"
              >
                {data?.grooveRecappingBoMm}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                R1
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                Groove (mm)
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getCellValue(data?.fleetRecapping?.[2]?.grooveMM as string)}
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                Total KM Bands
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getCellValue(
                  data?.fleetRecapping?.[2]?.brandTotalKM as string,
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                R2
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                Groove (mm)
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getCellValue(data?.fleetRecapping?.[0]?.grooveMM as string)}
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                Total KM Bands
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getCellValue(
                  data?.fleetRecapping?.[0]?.brandTotalKM as string,
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                R3
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                Groove (mm)
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getCellValue(data?.fleetRecapping?.[1]?.grooveMM as string)}
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                Total KM Bands
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getCellValue(
                  data?.fleetRecapping?.[1]?.brandTotalKM as string,
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th">
                {t('page.fleets.label.tireRecap.retreadBrand')}
              </Table.Cell>
              <Table.Cell className="td" colSpan="4">
                {getValuesFromArrayOfObjects(data?.usedBands)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireRecap.treadModel')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="4">
                {data?.bandModel}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireRecap.buffingRadius')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="4">
                {getValuesFromArrayOfObjects(data?.sweepRadius)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireRecap.retreadShop')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="4">
                {data?.sweepRadiusDescription}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Article>
  );
}
