import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../services/queryClient';
import { deleteTireEvaluationService } from '../../../services/tireEvaluation';

export function useDeleteTireEvaluation(
  handleDeleteConfirmationModalClose: () => void,
) {
  const { t } = useTranslation();

  return useMutation(
    async (id: number) => {
      const response = await deleteTireEvaluationService(id);

      handleDeleteConfirmationModalClose();

      return response;
    },
    {
      onSuccess: () => {
        toast.success(t('page.tireEvaluations.toast.success.delete'));
        queryClient.invalidateQueries('tireEvaluations');
      },
      onError: () => {
        toast.error(t('page.tireEvaluations.toast.error.delete'));
      },
    },
  );
}
