import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Loader, Popup } from 'semantic-ui-react';

import { useAuth } from '../../../contexts/auth';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import { LanguageLocationEnum } from '../../../locales';
import { downloadFileService } from '../../../services/fileService';
import { BASE_URL } from '../../../settings/constants';
import {
  AppName,
  ApplicationName,
  Container,
  Icons,
  Logo,
  Profile,
  SidebarButton,
  UserProfile,
} from './styles';

interface Props {
  handleShow: () => void;
}

function Header({ handleShow }: Props) {
  const { i18n, t } = useTranslation();
  const { user, isUserLoading, signOut } = useAuth();
  const { isMobileScreen } = useIsMobileScreen(460);

  const handleLogout = () => {
    signOut();
  };

  const userGuideDownload = () => {
    downloadFileService(
      'User_Guide_-_Field_Engeering_Projects_v1.pdf',
      'user_guides',
    );
  };

  const getUserInformation = useCallback(() => {
    if (isUserLoading) {
      return <Loader active inline />;
    }

    return (
      <>
        <div>
          <span>{user?.displayName}</span>
          <span>{user?.mail}</span>
        </div>
        <img
          src={
            user?.photo_url ??
            `https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${user?.displayName}`
          }
          alt="profile_photo"
        />
      </>
    );
  }, [isUserLoading, user]);

  const handleChangeLanguage = (language: LanguageLocationEnum) => {
    i18n.changeLanguage(language);
    localStorage.setItem('@LANGUAGE', i18n.language);
  };

  return (
    <Container>
      <SidebarButton onClick={handleShow}>
        <Icon name="bars" />
      </SidebarButton>

      <ApplicationName>
        <Link to={BASE_URL} className="header__title">
          <Logo />
          <AppName>Field Engineering Projects</AppName>
        </Link>
      </ApplicationName>

      <UserProfile>
        {!isMobileScreen && (
          <Icons>
            <Popup
              content={t('languages.label')}
              trigger={
                <Dropdown
                  trigger={
                    <Link to="##">
                      <Icon name="globe" />
                    </Link>
                  }
                  icon={null}
                  pointing="top right"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      flag="us"
                      text="English"
                      active={['en', 'en-US'].includes(i18n.language)}
                      onClick={() =>
                        handleChangeLanguage(LanguageLocationEnum.EN_US)
                      }
                    />
                    <Dropdown.Item
                      flag="br"
                      text="Português"
                      active={['pt-BR', 'PT'].includes(i18n.language)}
                      onClick={() =>
                        handleChangeLanguage(LanguageLocationEnum.PT_BR)
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              }
              position="right center"
            />
          </Icons>
        )}

        <Dropdown
          trigger={<Profile>{getUserInformation()}</Profile>}
          icon={null}
        >
          {!isUserLoading ? (
            <Dropdown.Menu className="header__profile-menu">
              {isMobileScreen && (
                <>
                  <Dropdown.Item>
                    <Dropdown
                      trigger={
                        <span>
                          <Icon name="language" /> {t('label.language')}
                        </span>
                      }
                      pointing="left"
                      direction={isMobileScreen ? 'left' : 'right'}
                      options={[
                        {
                          key: 1,
                          value: 1,
                          text: 'English',
                          onClick: () =>
                            handleChangeLanguage(LanguageLocationEnum.EN_US),
                          flag: 'us',
                        },
                        {
                          key: 2,
                          value: 2,
                          text: 'Português',
                          onClick: () =>
                            handleChangeLanguage(LanguageLocationEnum.PT_BR),
                          flag: 'br',
                        },
                      ]}
                    />
                  </Dropdown.Item>

                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item
                text={t('label.userGuide')}
                icon="info"
                onClick={userGuideDownload}
              />

              <Dropdown.Divider />
              <Dropdown.Item
                text={t('label.logout')}
                icon="sign out"
                onClick={handleLogout}
              />
            </Dropdown.Menu>
          ) : undefined}
        </Dropdown>
      </UserProfile>
    </Container>
  );
}

export { Header };
