import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Icon, Table } from 'semantic-ui-react';

import { getValuesFromArrayOfObjects } from '../../../../helpers/getValuesFromArrayOfObjects';
import { downloadFileService } from '../../../../services/fileService';
import { FleetRoutesAndControl } from '../../../../types/fleet';
import { Article } from '../components/Article';
import { Header } from '../components/Header';

interface IProps {
  data?: FleetRoutesAndControl;
}

export function RoutesAndControl({ data }: IProps) {
  const { t } = useTranslation();

  function handleRouteFileDownload(fileName: string) {
    downloadFileService(fileName, 'FLEET')
      .then(() => {
        toast.success('File download sucess');
      })
      .catch(() => {
        toast.error('File download error');
      });
  }

  return (
    <Article>
      <div className="table-container">
        <Table celled unstackable compact>
          <Table.Header colspan="5">
            <Table.Row>
              <Table.Cell className="title" colspan="7">
                <Header icon="road" content="Routes and Control" />
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.route?.map((item, index) => (
              <>
                <Table.Row>
                  <Table.Cell className="th" verticalAlign="top">{`${t(
                    'page.fleets.label.routesAndControl.routes',
                  )} ${index + 1}`}</Table.Cell>
                  <Table.Cell className="th" verticalAlign="top">
                    Origin / Destination
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top" colspan="4">
                    {item?.originCity &&
                      item?.originRegion &&
                      `${item?.originCity}, ${item?.originRegion}`}
                    <Icon name="shipping fast" style={{ margin: '0 1em' }} />
                    {item?.destinationCity &&
                      item?.destinationRegion &&
                      `${item?.destinationCity}, ${item?.destinationRegion}`}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="td" />
                  <Table.Cell className="th" verticalAlign="top">
                    Product loades
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top">
                    {item?.productLoades}
                  </Table.Cell>
                  <Table.Cell className="th" verticalAlign="top">
                    Quantity vehicles
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top" colspan="5">
                    {item?.qtyVehicles}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="td" />
                  <Table.Cell className="th" verticalAlign="top">
                    RSN X
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top">
                    {item?.rsnX}
                  </Table.Cell>
                  <Table.Cell className="th" verticalAlign="top">
                    RSN Y
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top">
                    {item?.rsnY}
                  </Table.Cell>
                  <Table.Cell className="th" verticalAlign="top">
                    RSN T
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top">
                    {item?.rsnT}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="td" />
                  <Table.Cell className="th" verticalAlign="top">
                    Attached file
                  </Table.Cell>
                  <Table.Cell className="td" verticalAlign="top" colspan="5">
                    <a
                      href="###"
                      onClick={() => handleRouteFileDownload(item?.fileRoute)}
                    >
                      {item?.fileRoute}
                    </a>
                  </Table.Cell>
                </Table.Row>
              </>
            ))}
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {t('page.fleets.label.routesAndControl.service')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {getValuesFromArrayOfObjects(data?.services)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.segment')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.segment}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.fixedFleetRoutes')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.fixedFleetRoutes ? 'YES' : 'NO'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.averageDistance')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.averageDistance}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.routeSeverity')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.routeSeverity}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.tireControl')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.tireControl ? 'YES' : 'NO'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.controlType')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {getValuesFromArrayOfObjects(data?.controlTypes)}
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top" width={2}>
                {t('page.fleets.label.routesAndControl.systemName')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.systemName}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.fleetHaveMaintenance')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.haveMaintenance ? 'YES' : 'NO'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.pressureControl')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.pressureControl ? 'YES' : 'NO'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t(
                  'page.fleets.label.routesAndControl.standardPressureUsedInFleet',
                )}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.pressureUsed}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.crissCross')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {data?.tireRotation ? 'YES' : 'NO'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.crissCrossType')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="6">
                {getValuesFromArrayOfObjects(data?.crissCrossType)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.routesAndControl.crissCrossObservation')}
              </Table.Cell>
              <Table.Cell
                className="td"
                verticalAlign="top"
                colSpan="6"
                width={8}
              >
                {data?.crissCrossObservation}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Article>
  );
}
