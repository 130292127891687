import { useTranslation } from 'react-i18next';
import { Confirm, Form } from 'semantic-ui-react';

import { LinkReportFormData } from '../../../../../../../../types/project';
import { useEditReportLink } from '../../hooks/useEditReportLink';

interface IProps {
  projectId?: number;
  reportLink: LinkReportFormData;

  isUpdateMoreReports: boolean;
  setIsUpdateMoreReports: React.Dispatch<React.SetStateAction<boolean>>;

  isConfirmationModalOpen: boolean;
  handleConfirmationModalClose(): void;

  setActiveAccordion: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function UploadTasksConfirmModal({
  projectId,
  reportLink,

  isUpdateMoreReports,
  setIsUpdateMoreReports,

  isConfirmationModalOpen,
  handleConfirmationModalClose,

  setActiveAccordion,
  setIsSubmitLoading,
}: IProps) {
  const { t } = useTranslation();
  const { editReportLink } = useEditReportLink({
    setActiveAccordion,
    setIsSubmitLoading,
  });

  return (
    <Confirm
      header="Upload Tasks"
      content={
        <div style={{ padding: '20px' }}>
          <p>
            Would you like the planned dates for upcoming reports of this
            project to be updated as well? (Yes/No)
          </p>
          <Form>
            <Form.Radio
              label="Yes"
              checked={isUpdateMoreReports}
              onChange={() => setIsUpdateMoreReports(true)}
            />
            <Form.Radio
              label="No"
              checked={!isUpdateMoreReports}
              onChange={() => setIsUpdateMoreReports(false)}
            />
          </Form>
        </div>
      }
      open={isConfirmationModalOpen}
      cancelButton={{
        content: t('confirm.delete.cancel'),
      }}
      confirmButton={{
        content: 'Update',
        positive: true,
        icon: 'cloud upload',
        labelPosition: 'left',
      }}
      onCancel={handleConfirmationModalClose}
      onConfirm={() => {
        const data = { projectId, ...reportLink };
        editReportLink.mutateAsync({ data, isUpdateMoreReports }).then(() => {
          handleConfirmationModalClose();
        });
      }}
      size="mini"
    />
  );
}
