import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'semantic-ui-react';

import { ISelectedScheduleTaskProps } from '../..';

interface Props {
  isConfirmationModalOpen: boolean;
  handleScheduleConfirmationModalClose(): void;
  selectedScheduleTask: ISelectedScheduleTaskProps;
  deleteScheduleTask: any;
}

export function TaskDeleteConfirmationModal({
  isConfirmationModalOpen,
  handleScheduleConfirmationModalClose,
  selectedScheduleTask,
  deleteScheduleTask,
}: Props) {
  const { t } = useTranslation();
  const [isDeleteTaskSeriesSelected, setIsDeleteTaskSeriesSelected] =
    useState<boolean>(false);
  return (
    <Modal
      size="mini"
      open={isConfirmationModalOpen}
      onClose={handleScheduleConfirmationModalClose}
    >
      <Modal.Header content={t('confirm.delete.title')} />
      <Modal.Content>
        {t('confirm.delete.message')}
        <Form.Group grouped>
          <Form.Radio
            label="Delete this occurrence"
            checked={!isDeleteTaskSeriesSelected}
            onChange={() => setIsDeleteTaskSeriesSelected(false)}
          />
          <Form.Radio
            label="Delete this series"
            checked={isDeleteTaskSeriesSelected}
            onChange={() => setIsDeleteTaskSeriesSelected(true)}
            disabled={selectedScheduleTask?.taskSeriesId === null}
          />
        </Form.Group>
      </Modal.Content>

      <Modal.Actions>
        <Button
          content={t('confirm.delete.cancel')}
          onClick={handleScheduleConfirmationModalClose}
        />
        <Button
          negative
          icon="trash"
          labelPosition="left"
          content={t('confirm.delete.confirm')}
          onClick={() =>
            deleteScheduleTask.mutateAsync({
              scheduleTaskId: selectedScheduleTask?.taskId,
              scheduleTaskSeriesId: selectedScheduleTask?.taskSeriesId,
              isDeleteTaskSeriesSelected,
            })
          }
        />
      </Modal.Actions>
    </Modal>
  );
}
