export const ENV = {
  REACT_APP_BASE_API_URL: String(window._env_.REACT_APP_BASE_API_URL),
  REACT_APP_BASE_URL: String(window._env_.REACT_APP_BASE_URL),
  REACT_APP_AUTHORIZE_BASE_API_URL: String(
    window._env_.REACT_APP_AUTHORIZE_BASE_API_URL,
  ),
  REACT_APP_CLIENT_ID: String(window._env_.REACT_APP_CLIENT_ID),
  REACT_APP_REDIRECT_URI: String(window._env_.REACT_APP_REDIRECT_URI),
  REACT_APP_TAG_GOOGLE: String(window._env_.REACT_APP_TAG_GOOGLE),
  REACT_APP_BACKGROUND_IMAGES: String(window._env_.REACT_APP_BACKGROUND_IMAGES),
};
