import { format } from 'date-fns';

export function getDateFormat(date: string | Date, formatStr: string) {
  if (date) {
    return format(new Date(date), formatStr);
  }
  return '';
}

export function getDateFormatView(data: string) {
  return format(new Date(data), 'yyyy/MM/dd');
}
