import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';

import { FleetGeneralInformation } from '../../../../types/fleet';
import { Article } from '../components/Article';
import { Header } from '../components/Header';

interface IProps {
  data?: FleetGeneralInformation;
}

export function GeneralInformation({ data }: IProps) {
  const { t } = useTranslation();

  return (
    <Article>
      <div className="table-container">
        <Table celled unstackable compact>
          <Table.Header>
            <Table.Row>
              <Table.Cell className="title" colspan="6">
                <Header icon="info circle" content="General information" />
              </Table.Cell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {t('page.fleets.label.generalInformation.fleetsName')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.name}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.state')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.region}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.city')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.city}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.type')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.type?.label}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.account')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.account?.label}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.shipTo')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.shipTo}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.soldTo')}
              </Table.Cell>
              <Table.Cell
                className="td no-wrap"
                verticalAlign="top"
                colSpan="5"
              >
                {data?.soldTo}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.generalInformation.subsidiaries')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="5">
                {data?.subsidiaries}
              </Table.Cell>
            </Table.Row>

            {data?.fleetContact?.map((contact, index) => (
              <Table.Row key={contact?.id}>
                <Table.Cell className="th" verticalAlign="top">
                  {`${t(
                    'page.fleets.label.generalInformation.fleetsContact',
                  )} ${index + 1}`}
                </Table.Cell>
                <Table.Cell className="td no-wrap" verticalAlign="top">
                  {contact.name}
                </Table.Cell>
                <Table.Cell className="th" verticalAlign="top">
                  Email
                </Table.Cell>
                <Table.Cell className="td no-wrap" verticalAlign="top">
                  {contact.email}
                </Table.Cell>
                <Table.Cell className="th" verticalAlign="top">
                  Phone
                </Table.Cell>
                <Table.Cell className="td no-wrap" verticalAlign="top">
                  {contact.phone}
                </Table.Cell>
              </Table.Row>
            ))}

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                PFC
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {data?.regionAdvisor?.name}
              </Table.Cell>
              <Table.Cell className="th" verticalAlign="top">
                Email
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {data?.regionAdvisor?.email}
              </Table.Cell>
              <Table.Cell className="th" verticalAlign="top">
                Phone
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {data?.regionAdvisor?.phone}
              </Table.Cell>
            </Table.Row>

            {data?.shareBusiness?.map((item, index) => (
              <Table.Row key={item?.id}>
                <Table.Cell className="th" verticalAlign="top">
                  {`${t(
                    'page.fleets.label.generalInformation.shareBusiness',
                  )} ${index + 1}`}
                </Table.Cell>
                <Table.Cell className="th">Brand</Table.Cell>
                <Table.Cell className="td no-wrap">{item.brand}</Table.Cell>
                <Table.Cell className="th">Share</Table.Cell>
                <Table.Cell
                  className="td no-wrap"
                  colSpan="2"
                >{`${item.share}%`}</Table.Cell>
              </Table.Row>
            ))}

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t(
                  'page.fleets.label.generalInformation.fleetCompetitorTesting',
                )}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="5">
                {data?.competitonTest}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Article>
  );
}
