import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { Button, Form } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import wingfootLogoImg from '../../../../assets/img/wingfoot-logo-yellow.png';
import { Container } from './styles';

interface IFilterBarProps {
  children: React.ReactNode;
  show: boolean;
  closeFilterBar(): void;
  onApplyClick(): void;
}

export default function FilterBar({
  children,
  closeFilterBar,
  show,
  onApplyClick,
}: IFilterBarProps) {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Container show={show}>
      <Form>
        <div className="filter__header">
          <img
            className="filter__logo"
            src={wingfootLogoImg}
            alt="Wing foot Logo"
          />
          <div className="filter__title">{t('toolbar.filter.title')}</div>
          <div className="filter__close">
            <IoMdClose onClick={closeFilterBar} fontSize="2em" />
          </div>
        </div>

        <div className="filter__body">{children}</div>

        <div className="filter__submit">
          <Button
            style={{
              backgroundColor: theme.colors.primary,
              color: theme.colors.secondary,
            }}
            width="1"
            content={t('toolbar.button.apply')}
            onClick={() => {
              onApplyClick();
              closeFilterBar();
            }}
            fluid
          />
        </div>
      </Form>
    </Container>
  );
}
