/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Divider, Form, Icon, Popup } from 'semantic-ui-react';

import { queryClient } from '../../../../../services/queryClient';
import {
  createTestTypesService,
  deleteTestTypesService,
  updateTestTypesService,
} from '../../../../../services/testTypeService';
import { PROJECT_TESTTYPE_QUERYKEY } from '../../../../../settings/constants';
import { TestType } from '../../../../../types/project';
import { Table } from './components/Table';

interface ITestTypeModalContentProps {
  testTypes?: TestType[];
}

export interface ItemsProps {
  id?: number;
  label: string;
  mask: string;
  isEdit: boolean;
}

export function TestTypeModalContent({
  testTypes,
}: ITestTypeModalContentProps) {
  const { t } = useTranslation();
  const [testType, setTestType] = useState<TestType>({
    label: '',
    mask: '',
  });
  const [items, setItems] = useState(
    testTypes?.map(
      (item): ItemsProps => ({
        id: item.id,
        label: item.label,
        mask: item.mask,
        isEdit: false,
      }),
    ),
  );

  useEffect(() => {
    setItems(
      testTypes?.map(item => ({
        id: item.id,
        label: item.label,
        mask: item.mask,
        isEdit: false,
      })),
    );
  }, [testTypes]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTestType({
      ...testType,
      [e.currentTarget.name]:
        e.currentTarget.name === 'label'
          ? e.currentTarget.value.toUpperCase()
          : e.currentTarget.value,
    });
  }

  const handleAddItem = useMutation(
    async (data: any) => {
      return createTestTypesService(data);
    },
    {
      onSuccess: () => {
        toast.success('Item created successfully');

        setTestType({
          label: '',
          mask: '',
        });

        queryClient.invalidateQueries(PROJECT_TESTTYPE_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error creating item');
        }
      },
    },
  );

  const handleUpdateItem = useMutation(
    async (data: any) => {
      return updateTestTypesService(data);
    },
    {
      onSuccess: () => {
        toast.success('Item updated successfully');

        queryClient.invalidateQueries(PROJECT_TESTTYPE_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error updating item');
        }
      },
    },
  );

  const handleDeleteItem = useMutation(
    async (id: number) => {
      await deleteTestTypesService(id);
    },
    {
      onSuccess: () => {
        toast.success('Item deleted successfully');
        queryClient.invalidateQueries(PROJECT_TESTTYPE_QUERYKEY);
      },
      onError: () => {
        toast.error('This item is already in use');
      },
    },
  );

  return (
    <>
      <Form>
        <Form.Group grouped>
          <Form.Group unstackable widths="equal">
            <Form.Input
              label={t('component.specialEditableDropdown.testType.label.name')}
              name="label"
              value={testType.label}
              onChange={e => handleChange(e)}
              width="10"
            />

            <Form.Input
              icon
              type="text"
              label={t('component.specialEditableDropdown.testType.label.mask')}
              name="mask"
              value={testType.mask}
              onChange={e => handleChange(e)}
              width="6"
            >
              <input />
              <Popup
                trigger={<Icon link name="info circle" />}
                content={
                  <>
                    <b>9: </b>
                    0-9
                    <br />
                    <b>a: </b>
                    a-z, A-Z
                    <br />
                    <b>*: </b>
                    0-9, a-z, A-Z
                    <br />
                    <Divider />
                    <b>Ex: </b>
                    T-9999-99
                    <br />
                  </>
                }
              />
            </Form.Input>
          </Form.Group>

          <Form.Button
            type="button"
            content="ADD"
            color="green"
            onClick={() => handleAddItem.mutateAsync(testType)}
            disabled={!testType.label || !testType.mask}
            fluid
          />
        </Form.Group>
      </Form>
      <Table
        items={items}
        setItems={setItems}
        handleUpdateItem={handleUpdateItem}
        handleDeleteItem={handleDeleteItem}
      />
    </>
  );
}
