import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../../../services/queryClient';
import { createScheduleHolidayService } from '../../../../../services/schedule';

interface ItemProps {
  holidayDate: string;
  description: string;
}

export function useHandleCreateHoliday() {
  const { t } = useTranslation();

  const handleCreateHoliday = useMutation(
    (item: ItemProps) => {
      return createScheduleHolidayService(item);
    },
    {
      onSuccess: () => {
        toast.success(t('generics.toast.success.create'));
        queryClient.invalidateQueries('HOLIDAY_LIST_MANAGEMENT');
      },
      onError: () => {
        toast.error(t('generics.toast.error.create'));
      },
    },
  );

  return {
    handleCreateHoliday,
  };
}
