import { useTranslation } from 'react-i18next';
import { Table as SUITable, Icon } from 'semantic-ui-react';

import { usePermission } from '../../../../../hooks/usePermission';

export function TableHeader() {
  const { t } = useTranslation();
  const { isEditor } = usePermission();

  return (
    <SUITable.Header className="thead">
      <SUITable.Row className="tr">
        <SUITable.HeaderCell className="th id" scope="col">
          Id
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerBrand" scope="col">
          {t('page.tireEvaluations.label.brand')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerModel" scope="col">
          {t('page.tireEvaluations.label.model')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerModification" scope="col">
          {t('page.tireEvaluations.label.modification')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerSize" scope="col">
          {t('page.tireEvaluations.label.size')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerTiresQty" scope="col">
          {t('page.tireEvaluations.label.tiresQty')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerFleet" scope="col">
          {t('page.tireEvaluations.label.fleet')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerService" scope="col">
          {t('page.tireEvaluations.label.service')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerTirePosition" scope="col">
          {t('page.tireEvaluations.label.tirePosition')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell
          className="th headerEvaluationResponsible"
          scope="col"
        >
          {t('page.tireEvaluations.label.responsible')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerAssemblyDate" scope="col">
          {t('page.tireEvaluations.label.assemblyDate')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerAssemblyDate" scope="col">
          {t('page.tireEvaluations.label.status')}
        </SUITable.HeaderCell>
        <SUITable.HeaderCell className="th headerReports" scope="col">
          {t('page.tireEvaluations.label.reports')}
        </SUITable.HeaderCell>

        {isEditor && (
          <SUITable.HeaderCell className="th action" scope="col">
            <Icon name="cog" size="large" />
          </SUITable.HeaderCell>
        )}
      </SUITable.Row>
    </SUITable.Header>
  );
}
