import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { CheckboxProps, DropdownProps, Loader } from 'semantic-ui-react';

import { Page } from '../../components/Container';
import { Toolbar } from '../../components/Toolbar';
import {
  getItemsSearchListService,
  searchExcelDownload,
} from '../../services/search';
import { ApiSearchProps } from '../../types/search';
import { FilterFormData } from './components/FilterFormData';
import { PrimaryTable } from './components/PrimaryTable';
import { SecondaryTable } from './components/SecondaryTable';
import ShowHideColumnsModal from './components/ShowHideColumnsModal';
import useFiltersOptions from './Hooks/useFiltersOptions';

export interface IShowColumnsProps {
  folder: boolean;
  description?: boolean;
  fleet?: boolean;
  fleetType?: boolean;
  service?: boolean;
  brand?: boolean;
  model?: boolean;
  size?: boolean;
  modification?: boolean;
  tiresQty?: boolean;
  responsible?: boolean;
}

export function Search() {
  const { t } = useTranslation();
  const [toggleSearchPageTable, setToggleTable] = useState(false);
  const [isShowHideModalOpen, setShowHideModalOpen] = useState(false);
  const [showColumnsGroup, setShowColumnsGroup] = useState<boolean | undefined>(
    true,
  );
  const [showColumns, setshowColumns] = useState<IShowColumnsProps>({
    folder: true,
    description: true,
    fleet: true,
    fleetType: true,
    service: true,
    brand: true,
    model: true,
    size: true,
    modification: true,
    tiresQty: true,
    responsible: true,
  });
  const [page, setPage] = useState<any>(1);
  const [query, setQuery] = useState<ApiSearchProps>({});
  const [querySelected, setQuerySelected] = useState<ApiSearchProps>({});

  const { data: searchData, isLoading: isSearchDataLoading } = useQuery(
    ['search', { page, ...query }],
    async () => getItemsSearchListService({ page: page - 1, ...query }),
    {
      retry: false,
    },
  );

  const filtersOptions = useFiltersOptions();

  function handleShowHideModalOpen() {
    setShowHideModalOpen(true);
  }

  function handleShowHideModalClose() {
    setShowHideModalOpen(false);
  }

  function handleShowColumn(data: CheckboxProps) {
    setshowColumns(prevState => ({
      ...prevState,
      [data.name as string]: data.checked,
    }));
  }

  function handleShowColumnsGroup(data: CheckboxProps) {
    setShowColumnsGroup(data.checked);
    setshowColumns(prevState => ({
      ...prevState,
      description: data.checked,
      fleet: data.checked,
      fleetType: data.checked,
      service: data.checked,
      brand: data.checked,
      model: data.checked,
      size: data.checked,
      modification: data.checked,
      tiresQty: data.checked,
      responsible: data.checked,
    }));
  }

  function handleQuerySelected(
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) {
    setQuerySelected({
      ...querySelected,
      [data.name]: data.value,
    });
  }

  function onApplyClick() {
    setQuery({ ...querySelected });
    setPage(1);
  }

  function handleToggleTables() {
    setToggleTable(prevState => !prevState);
  }

  function getDisplayTable() {
    return toggleSearchPageTable ? (
      <SecondaryTable
        showColumns={showColumns}
        searchData={searchData}
        page={page}
        setPage={setPage}
      />
    ) : (
      <PrimaryTable
        showColumns={showColumns}
        searchData={searchData}
        page={page}
        setPage={setPage}
      />
    );
  }

  function handleDownloadExcel() {
    searchExcelDownload({ params: query });
  }

  return (
    <>
      <Page>
        <div className="page__title">{t('label.search')}</div>

        <Toolbar
          disableNewButton
          enableExportFileButton
          enableToggleTables
          enableShowHideButton
          handleShowHideModalOpen={handleShowHideModalOpen}
          handleToggleTablesSearch={handleToggleTables}
          onApplyClick={onApplyClick}
          handleDownloadExcel={handleDownloadExcel}
          toggleSearchPageTable={toggleSearchPageTable}
        >
          <FilterFormData
            filtersOptions={filtersOptions}
            querySelected={querySelected}
            handleQuerySelected={handleQuerySelected}
          />
        </Toolbar>

        {isSearchDataLoading ? (
          <Loader size="large" active />
        ) : (
          getDisplayTable()
        )}
      </Page>

      <ShowHideColumnsModal
        isShowHideModalOpen={isShowHideModalOpen}
        handleShowHideModalClose={handleShowHideModalClose}
        showColumnsGroup={showColumnsGroup}
        handleShowColumnsGroup={handleShowColumnsGroup}
        showColumns={showColumns}
        handleShowColumn={handleShowColumn}
      />
    </>
  );
}
