import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

import projectPackage from '../../../../package.json';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { menuItems } from './constants/menu';
import {
  Container,
  Navigation,
  Items,
  Item,
  Info,
  Logo,
  ToggleButton,
  SubItem,
  Category,
} from './styles';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleShow: () => void;
}

function Sidebar({ show, setShow, handleShow }: Props) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const { isMobileScreen } = useIsMobileScreen(460);
  const currentYear = new Date().getFullYear();
  const [categoryIndexOpen, setCategoryIndexOpen] = useState<number>();

  useOnClickOutside(ref, () => setShow(false));

  function handleClose() {
    if (show) {
      handleShow();
    }
  }

  const handleCategoryClick = useCallback(
    (index: number) => {
      const isCategoryOpen = categoryIndexOpen === index;

      if (!show) {
        handleShow();
        return setCategoryIndexOpen(index);
      }

      if (isCategoryOpen) {
        return setCategoryIndexOpen(0);
      }

      return setCategoryIndexOpen(index);
    },
    [categoryIndexOpen, show, handleShow],
  );

  return (
    <Container show={show} ref={ref}>
      <Navigation>
        <ToggleButton
          show={show}
          name={show ? 'close' : 'bars'}
          onClick={handleShow}
        />

        <hr style={{ opacity: 0.1 }} />

        <Items show={show}>
          {menuItems.map(
            ({ path, name, iconName, restrictedToAdmin, subItems }, index) => {
              if (restrictedToAdmin) {
                return undefined;
              }

              if (subItems) {
                return (
                  <Category
                    key={name}
                    active={subItems
                      .map(subItem => subItem.path)
                      .includes(location.pathname)}
                    isSidebarOpen={show}
                    isCategoryOpen={categoryIndexOpen === index}
                    onClick={() => handleCategoryClick(index)}
                  >
                    <span className="menu__category--item">
                      <Icon name={iconName} />
                      <span>
                        {t(name)}
                        <Icon
                          name={
                            categoryIndexOpen === index
                              ? 'angle down'
                              : 'angle right'
                          }
                        />
                      </span>
                    </span>

                    <span className="menu__category--subItems">
                      {subItems.map(subItem => {
                        if (subItem.restrictedToAdmin) {
                          return undefined;
                        }

                        return (
                          <SubItem
                            key={subItem.name}
                            active={location.pathname === subItem.path}
                          >
                            <Link
                              to={subItem.path ?? '*'}
                              onClick={handleClose}
                            >
                              <span>{t(subItem.name)}</span>
                            </Link>
                          </SubItem>
                        );
                      })}
                    </span>
                  </Category>
                );
              }

              return (
                <Popup
                  disabled={isMobileScreen || show}
                  inverted
                  content={t(name)}
                  trigger={
                    <Item
                      onClick={handleClose}
                      active={location.pathname === path}
                    >
                      <Link to={path as any}>
                        <Icon name={iconName} />
                        <span>{t(name)}</span>
                      </Link>
                    </Item>
                  }
                />
              );
            },
          )}
        </Items>
      </Navigation>

      <Info show={show}>
        <Logo />

        <div className="info__version-and-year">
          <span className="info__version">v{projectPackage.version}</span>
          <span className="info__year">© {currentYear}</span>
        </div>

        <div className="info__sentence">The Goodyear Tire & Rubber Company</div>
      </Info>
    </Container>
  );
}

export { Sidebar };
