import { EventInput } from '@fullcalendar/core';
import { format } from 'date-fns';

export function validHolidayDate(
  scheduleEventList?: EventInput[],
  date?: Date,
) {
  const dateStr = date && format(date, 'yyyy-MM-dd');

  return !!scheduleEventList?.find(
    ({ start, display }) =>
      start?.toString()?.split('T')[0] === dateStr && display === 'background',
  )?.extendedProps?.isHoliday;
}
