/* eslint-disable react/no-unescaped-entities */

import { useTranslation } from 'react-i18next';
import { Icon, Table as TableSemantic } from 'semantic-ui-react';

import { usePermission } from '../../../../hooks/usePermission';

export function HeaderTable() {
  const { t } = useTranslation();
  const { isEditor } = usePermission();

  return (
    <TableSemantic.Header className="thead">
      <TableSemantic.Row className="tr">
        <TableSemantic.HeaderCell className="th id" scope="col">
          {t('page.tireEvaluations.header.id')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th report" scope="col">
          {t('page.tireEvaluations.header.report')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th avg" scope="col">
          {t('page.tireEvaluations.header.avg')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th wornOut" scope="col">
          {t('page.tireEvaluations.header.wornOut')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th kmRan" scope="col">
          {t('page.tireEvaluations.header.kmRan')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th kmMM" scope="col">
          {t('page.tireEvaluations.header.kmMM')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th kmMMRate" scope="col">
          {t('page.tireEvaluations.header.kmMMRate')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th kmProjected" scope="col">
          {t('page.tireEvaluations.header.kmProjected')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th kmProjectedRate" scope="col">
          {t('page.tireEvaluations.header.kmProjectedRate')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th reportIssued" scope="col">
          {t('page.tireEvaluations.header.reportIssued')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th cycle" scope="col">
          {t('page.reports.label.lifeStatus')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th retreadIndex" scope="col">
          {t('page.reports.label.retreadIndex')}
        </TableSemantic.HeaderCell>
        <TableSemantic.HeaderCell className="th dateIssued" scope="col">
          {t('page.reports.label.dateIssuedTotalCycle')}
        </TableSemantic.HeaderCell>
        {isEditor && (
          <TableSemantic.HeaderCell className="th action" scope="col">
            <Icon name="cog" size="large" />
          </TableSemantic.HeaderCell>
        )}
      </TableSemantic.Row>
    </TableSemantic.Header>
  );
}
