import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxProps, Form } from 'semantic-ui-react';

interface IFieldBooleanRadios {
  controllerName: string;
  radioName: string;
  label: string;
}

export function FieldBooleanRadios({
  controllerName,
  radioName,
  label,
}: IFieldBooleanRadios) {
  const { control } = useFormContext();

  function handleYesOrNoChange(
    data: CheckboxProps,
    onChange: (...event: any[]) => void,
  ) {
    if (data.name) {
      if (data.value === 'YES') {
        onChange(data.checked);
      } else if (data.value === 'NO') {
        onChange(!data.checked);
      }
    }
  }

  return (
    <Controller
      control={control}
      name={controllerName}
      render={({ field: { onChange, value } }) => (
        <Form.Group grouped>
          <label>{label}</label>

          <Form.Group inline>
            <Form.Radio
              label="YES"
              value="YES"
              name={radioName}
              checked={value}
              onChange={(_, data) => handleYesOrNoChange(data, onChange)}
            />
            <Form.Radio
              label="NO"
              value="NO"
              name={radioName}
              checked={!value}
              onChange={(_, data) => handleYesOrNoChange(data, onChange)}
            />
          </Form.Group>
        </Form.Group>
      )}
    />
  );
}
