import { useTranslation } from 'react-i18next';

import { MacroOverviewProps } from '../../../../types/dashboard';
import { DashboardRow } from '../../styles';
import { BasicChart } from '../Charts';

interface IProps {
  chardData: MacroOverviewProps;
}

export function MacroOverview({ chardData }: IProps) {
  const { t } = useTranslation();

  return (
    <DashboardRow>
      <BasicChart
        title={t('page.dashboard.basicChats.projects')}
        value={chardData.basicCharts?.projects}
        color="#fcba03"
        isLoading={chardData?.isLoadingBasicCharts}
      />
      <BasicChart
        title={t('page.dashboard.basicChats.fleets')}
        value={chardData.basicCharts?.fleets}
        color="#46c746"
        isLoading={chardData?.isLoadingBasicCharts}
      />
      <BasicChart
        title={t('page.dashboard.basicChats.tiresRunning')}
        value={chardData.basicCharts?.tiresOnGoing}
        color="#0398fc"
        isLoading={chardData?.isLoadingBasicCharts}
      />
      <BasicChart
        title={t('page.dashboard.basicChats.engineers')}
        value={chardData.basicCharts?.engineers}
        color="#ad5cff"
        isLoading={chardData?.isLoadingBasicCharts}
      />
    </DashboardRow>
  );
}
