import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Checkbox, Loader } from 'semantic-ui-react';

import { ChartDataProps } from '../../../../../types/dashboard';
import { Container } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface IDataProps {
  title: string;
  data?: ChartDataProps;
  isLoading?: boolean;
  indexAxis?: 'x' | 'y';
  position?: string;
  color?: string;
  disablePercent?: boolean;
}

export function BasicHorBarChart({
  title,
  data,
  isLoading,
  indexAxis,
  position,
  color,
  disablePercent = false,
}: IDataProps) {
  const [isDataInPercent, setIsDataInPercent] = useState(false);

  function handleTogglePercent() {
    setIsDataInPercent(prevState => !prevState);
  }

  const options = {
    indexAxis: indexAxis ?? ('y' as const),
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value: any) => {
          if (isDataInPercent && value) {
            return `${value}%`;
          }
          return value === 0 ? null : value;
        },
      },
      legend: {
        display: false,
        position: position ?? ('top' as any),
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <Container>
      <div className="minichartbox__header">
        <div className="minichartbox__title">{title}</div>
        {!disablePercent && (
          <Checkbox
            checked={isDataInPercent}
            onChange={handleTogglePercent}
            label="%"
          />
        )}
      </div>
      <div className="minichartbox__content">
        {isLoading ? (
          <Loader inline active />
        ) : (
          <Bar
            options={options}
            data={{
              labels: data?.labels ?? [],
              datasets: [
                {
                  label: 'Total Tires',
                  data: isDataInPercent
                    ? data?.datasets[0].dataInPercent
                    : data?.datasets[0].data,
                  backgroundColor: color ?? '#ffce56',
                  datalabels: {
                    anchor: 'center',
                    backgroundColor: '#FFFFFF20',
                    borderRadius: 25,
                    borderWidth: 2,
                    color: 'black',
                    labels: {
                      title: {
                        font: {
                          weight: 'bold',
                        },
                      },
                      value: {
                        color: '#333333',
                      },
                    },
                  },
                },
              ],
            }}
          />
        )}
      </div>
    </Container>
  );
}
