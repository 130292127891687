import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

interface ButtonProps {
  outline: boolean;
}

export const Container = styled(Button)<ButtonProps>`
  border: ${({ theme, outline }) =>
    outline && `2px solid ${theme.colors.primary}`} !important;
  background-color: ${({ theme, outline }) =>
    outline ? theme.colors.white : theme.colors.primary} !important;
  color: ${({ theme, outline }) =>
    outline ? theme.colors.primary : theme.colors.white} !important;

  i {
    opacity: 1 !important;
    color: ${({ theme, outline }) =>
      outline ? theme.colors.primary : theme.colors.white} !important;
  }

  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;

  &:hover {
    background-color: ${({ theme, outline }) =>
      !outline && theme.colors.primary_dark} !important;
  }
`;
