/* eslint-disable react/no-unescaped-entities */

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Form, Input } from 'semantic-ui-react';

import { EditableDropdown } from '../../../../components/Form/EditableDropdown';
import { toDropdownItemProps } from '../../../../helpers/toDropdownItemProps';
import {
  getTireAverageKmMonthService,
  createTireAverageKmMonthService,
  updateTireAverageKmMonthService,
  deleteTireAverageKmMonthService,
} from '../../../../services/tireAverageKMService';
import {
  getTireBrandService,
  createTireBrandService,
  updateTireBrandService,
  deleteTireBrandService,
} from '../../../../services/tireBrandService';
import {
  getTireSizeService,
  createTireSizeService,
  updateTireSizeService,
  deleteTireSizeService,
} from '../../../../services/tireSizeService';
import {
  FLEET_TIREAVGKMMONTH_QUERYKEY,
  FLEET_TIREBRAND_QUERYKEY,
  MAX_CHARS_TEXT_AREA,
  TIRESIZE_QUERYKEY,
} from '../../../../settings/constants';

export default function RubberShopAndTires() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { data: tireBrands } = useQuery(FLEET_TIREBRAND_QUERYKEY, async () =>
    toDropdownItemProps(await getTireBrandService()),
  );

  const { data: tireAverageKm } = useQuery(
    FLEET_TIREAVGKMMONTH_QUERYKEY,
    async () => toDropdownItemProps(await getTireAverageKmMonthService()),
  );

  const { data: tireSize } = useQuery(TIRESIZE_QUERYKEY, async () =>
    toDropdownItemProps(await getTireSizeService()),
  );

  return (
    <>
      <EditableDropdown
        controllerControl={control}
        controllerName="rubberShopAndTires.tireBrands"
        label={t('page.fleets.label.tireShop.tireBrandsUsed')}
        queryKey={FLEET_TIREBRAND_QUERYKEY}
        multiple
        options={tireBrands}
        createItemService={createTireBrandService}
        updateItemService={updateTireBrandService}
        deleteItemService={deleteTireBrandService}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="rubberShopAndTires.tireAveragesKm"
        label={t('page.fleets.label.tireShop.totalAverageKmMonth')}
        queryKey={FLEET_TIREAVGKMMONTH_QUERYKEY}
        multiple
        options={tireAverageKm}
        createItemService={createTireAverageKmMonthService}
        updateItemService={updateTireAverageKmMonthService}
        deleteItemService={deleteTireAverageKmMonthService}
      />

      <Form.Group widths="equal">
        <Controller
          control={control}
          name="rubberShopAndTires.mediaKmMm"
          render={({ field: { value, onChange, onBlur } }) => (
            <Form.Input
              maxLength={200}
              label={t('page.fleets.label.tireShop.averageKmMm')}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              width="9"
            />
          )}
        />

        <Controller
          control={control}
          name="rubberShopAndTires.cpk"
          render={({ field: { value, onChange, onBlur } }) => (
            <Form.Field width="7">
              <label>{t('page.fleets.label.tireShop.cpk')}</label>
              <Input
                label={{ content: 'mm' }}
                labelPosition="right"
                value={value}
                onChange={(_, data) => onChange(data.value.replace(',', '.'))}
                onBlur={onBlur}
              />
            </Form.Field>
          )}
        />
      </Form.Group>

      <Controller
        control={control}
        name="rubberShopAndTires.competitorPerfomance"
        render={({ field: { value, onChange, onBlur } }) => (
          <Form.TextArea
            type="text"
            maxLength={MAX_CHARS_TEXT_AREA}
            label={t('page.fleets.label.tireShop.competitorPerfomance')}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="rubberShopAndTires.tireSize"
        label={t('page.fleets.label.tireShop.fleetTiresSize')}
        queryKey={TIRESIZE_QUERYKEY}
        multiple
        options={tireSize}
        createItemService={createTireSizeService}
        updateItemService={updateTireSizeService}
        deleteItemService={deleteTireSizeService}
        activeInstructions
        instructions={
          <>
            <p>
              <span>
                When add new size, please, use <mark>"/"</mark> and{' '}
                <mark>"."</mark> in the items.
              </span>
              <br />
            </p>
            <p>
              Example: xxx/xxRxx.x <br />
            </p>
          </>
        }
      />

      <Controller
        control={control}
        name="rubberShopAndTires.tireExitReason"
        render={({ field: { value, onChange, onBlur } }) => (
          <Form.TextArea
            type="text"
            maxLength={MAX_CHARS_TEXT_AREA}
            label={t('page.fleets.label.tireShop.tireOutServiceReasons')}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
    </>
  );
}
