import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Image, Label, Table } from 'semantic-ui-react';

import { IShowColumnsProps } from '../../../../../../types/search';
import { TireEvaluation } from '../../../../../../types/tireEvaluation';
import { getDateFormat } from '../../../../../../utils/getDateFormat';

interface ITableBodyProps {
  tireEvaluation: TireEvaluation;
  maxReportsLength: number;
  showColumns: IShowColumnsProps;
}

export default function TableBody({
  tireEvaluation,
  maxReportsLength,
  showColumns,
}: ITableBodyProps) {
  const { t } = useTranslation();
  const completeReports = maxReportsLength - tireEvaluation.reports.length;

  return (
    <Table.Body className="tbody">
      <Table.Row className="tr noRowspan">
        {showColumns.folder && (
          <Table.Cell className="td">
            {tireEvaluation.project.folder}
          </Table.Cell>
        )}
        {showColumns.description && (
          <Table.Cell className="td">
            {tireEvaluation.project.description}
          </Table.Cell>
        )}
        {showColumns.fleet && (
          <Table.Cell className="td">{tireEvaluation.fleet.name}</Table.Cell>
        )}
        {showColumns.fleetType && (
          <Table.Cell className="td">
            {tireEvaluation.fleet.type?.label}
          </Table.Cell>
        )}
        {showColumns.service && (
          <Table.Cell className="td">
            <Label.Group>
              {tireEvaluation.fleet.services?.map(service => (
                <Label key={service.id}>{service.label}</Label>
              ))}
            </Label.Group>
          </Table.Cell>
        )}
        {showColumns.brand && (
          <Table.Cell className="td">
            <Label.Group>
              <Label>{tireEvaluation.tire.brand}</Label>
            </Label.Group>
          </Table.Cell>
        )}
        {showColumns.model && (
          <Table.Cell className="td">{tireEvaluation.tire.model}</Table.Cell>
        )}
        {showColumns.size && (
          <Table.Cell className="td">
            {tireEvaluation.tire.tireSize?.label}
          </Table.Cell>
        )}
        {showColumns.modification && (
          <Table.Cell className="td">
            {tireEvaluation.tire.modification}
          </Table.Cell>
        )}
        {showColumns.tiresQty && (
          <Table.Cell className="td">{tireEvaluation.tireQuantity}</Table.Cell>
        )}
        {showColumns.responsible && (
          <Table.Cell className="td">
            <Header as="h4" image>
              <Image
                src={`https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${tireEvaluation.responsible.name}`}
                rounded
                size="mini"
              />
              <Header.Content>
                {tireEvaluation.responsible.name}
                <Header.Subheader>
                  {tireEvaluation.responsible.email}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
        )}
        {tireEvaluation.reports?.map(report => {
          return (
            <Fragment key={report.id}>
              <Table.Cell className="td">{report.percentage.label}</Table.Cell>
              <Table.Cell className="td">{report.avgNskMm}</Table.Cell>
              <Table.Cell className="td">{report.wornOut}</Table.Cell>
              <Table.Cell className="td">{report.kmRun}</Table.Cell>
              <Table.Cell className="td">{report.kmMm}</Table.Cell>
              <Table.Cell className="td">{report.kmMmRate}</Table.Cell>
              <Table.Cell className="td">{report.kmProject}</Table.Cell>
              <Table.Cell className="td">{report.kmProjectRate}</Table.Cell>
              <Table.Cell className="td">
                {report.reportDate &&
                  getDateFormat(report.reportDate, t('label.date'))}
              </Table.Cell>
              <Table.Cell className="td">{report.cycle}</Table.Cell>
              <Table.Cell className="td">{report.retreadIndex}</Table.Cell>
              <Table.Cell className="td">
                {report.dateIssued &&
                  getDateFormat(report.dateIssued, t('label.date'))}
              </Table.Cell>
            </Fragment>
          );
        })}
        {Array(completeReports)
          .fill('')
          .map(() => (
            <>
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
              <Table.Cell className="td" />
            </>
          ))}
      </Table.Row>
    </Table.Body>
  );
}
