import { SemanticICONS } from 'semantic-ui-react';

import {
  BASE_URL,
  FLEETS_PAGE,
  PROJECTS_PAGE,
  SCHEDULER_PAGE,
  SEARCH_PAGE,
} from '../../../../settings/constants';

type MenuItem = {
  path?: string;
  name: string;
  iconName: SemanticICONS;
  restrictedToAdmin?: boolean;

  subItems?: MenuItem[];
};

export const menuItems: MenuItem[] = [
  {
    path: BASE_URL,
    name: 'title.dashboard',
    iconName: 'chart bar',
  },
  {
    path: SCHEDULER_PAGE,
    name: 'title.scheduler',
    iconName: 'calendar alternate',
  },
  {
    path: `${PROJECTS_PAGE}/1`,
    name: 'title.projects',
    iconName: 'wrench',
  },
  {
    path: FLEETS_PAGE,
    name: 'title.fleets',
    iconName: 'car',
  },
  {
    path: SEARCH_PAGE,
    name: 'label.search',
    iconName: 'search',
  },
];
