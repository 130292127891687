import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Confirm, DropdownProps, Form, Loader } from 'semantic-ui-react';

import { Page } from '../../components/Container';
import { FilterGroup } from '../../components/FilterGroup';
import { Toolbar } from '../../components/Toolbar';
import api from '../../services/api';
import {
  deleteProjectService,
  getProjectListService,
} from '../../services/project';
import { queryClient } from '../../services/queryClient';
import { getUserListService } from '../../services/user';
import { USERS_QUERYKEY } from '../../settings/constants';
import { ProjectFormModal } from './components/ProjectFormModal';
import { Table } from './components/Table/index';

interface FilterParams {
  responsible?: number;
  folder?: string;
}

type ProjectParams = 'projectPage';

const PROJECT_PAGE = '@projectPage';

export default function Projects() {
  const { t } = useTranslation();
  const [page, setPage] = useState(
    Number(sessionStorage?.getItem(PROJECT_PAGE)) || 1,
  );
  const { projectPage } = useParams<ProjectParams>();

  useEffect(() => {
    sessionStorage.setItem(PROJECT_PAGE, String(page) || '1');
    setPage(Number(projectPage));
  }, [page, projectPage]);

  const [query, setQuery] = useState<FilterParams>({
    folder: undefined,
    responsible: undefined,
  });
  const [filterParams, setFilterParams] = useState<FilterParams>({
    folder: undefined,
    responsible: undefined,
  });

  const [isProjectFormModalOpen, setProjectFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [selectedProjectId, setSelectedProjectId] = useState<number>();

  const { data: projects, isLoading } = useQuery(
    ['projects', { page, ...query }],
    async () => {
      const response = await getProjectListService({
        page: page - 1,
        ...query,
      });

      if (response._embedded.projects) {
        const projectsPromise = await Promise.all(
          response._embedded.projects?.map(async project => {
            const tireEvaluations = await api.get(
              `projects/${project.id}/tireEvaluations`,
            );

            return {
              ...project,
              tireEvaluations: tireEvaluations.data,
            };
          }),
        );

        return {
          ...response,
          _embedded: {
            projects: projectsPromise,
          },
        };
      }

      return response;
    },
  );

  const { data: users } = useQuery(`${USERS_QUERYKEY}_FILTER`, async () => {
    const response = await getUserListService();

    return response.map(user => ({
      key: user.id,
      text: user.name,
      value: user.id,
    }));
  });

  function handleProjectFormModalOpen(projectId?: number) {
    if (projectId) {
      setSelectedProjectId(projectId);
    }
    setProjectFormModalOpen(true);
  }

  function handleProjectFormModalClose() {
    setSelectedProjectId(undefined);
    setProjectFormModalOpen(false);
  }

  function handleProjectConfirmationModalOpen(projectId: number) {
    setSelectedProjectId(projectId);
    setConfirmationModalOpen(true);
  }

  function handleProjectConfirmationModalClose() {
    setSelectedProjectId(undefined);
    setConfirmationModalOpen(false);
  }

  const handleDeleteProject = useMutation(
    async (id: number) => {
      const response = await deleteProjectService(id);

      handleProjectConfirmationModalClose();

      return response;
    },
    {
      onSuccess: () => {
        toast.success('Project deleted successfully');
        queryClient.invalidateQueries('projects');
      },
      onError: (error: any) => {
        if (error.response.data.error.code === 'BAD_REQUEST') {
          toast.error(
            'It was not possible delete this project because has a tire evaluation associated',
          );
        } else {
          toast.error('Error deleting project');
        }
      },
    },
  );

  function handleFilterParamsProjectState(
    value: React.ChangeEvent<HTMLInputElement>,
  ) {
    setFilterParams({
      ...filterParams,
      [value.currentTarget.name]:
        value.currentTarget.value === ''
          ? undefined
          : value.currentTarget.value,
    });
  }

  function handleResponsibleChange(
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) {
    setFilterParams({
      ...filterParams,
      responsible: data.value as number,
    });
  }

  function handleClickApplyFilter() {
    setQuery({
      ...filterParams,
    });
    setPage(1);
  }

  return (
    <>
      <Page>
        <div className="page__title">{t('title.projects')}</div>
        <Toolbar
          onAddClick={() => handleProjectFormModalOpen()}
          onApplyClick={handleClickApplyFilter}
        >
          <FilterGroup>
            <Form.Input
              label={t('page.projects.label.folder')}
              name="folder"
              value={filterParams.folder || ''}
              onChange={handleFilterParamsProjectState}
              fluid
            />
            <Form.Dropdown
              search
              clearable
              selection
              label={t('page.projects.label.responsible')}
              options={users ?? []}
              value={filterParams.responsible || ''}
              onChange={handleResponsibleChange}
              fluid
            />
          </FilterGroup>
        </Toolbar>

        {isLoading ? (
          <Loader size="large" active />
        ) : (
          <Table
            projects={projects}
            page={page}
            setPage={setPage}
            handleProjectFormModalOpen={handleProjectFormModalOpen}
            handleProjectDeleteModalOpen={handleProjectConfirmationModalOpen}
          />
        )}
      </Page>

      {isProjectFormModalOpen && (
        <ProjectFormModal
          isOpen={isProjectFormModalOpen}
          projectId={selectedProjectId}
          users={users}
          onRequestClose={handleProjectFormModalClose}
        />
      )}
      {isConfirmationModalOpen && selectedProjectId && (
        <Confirm
          header={t('confirm.delete.title')}
          content={t('confirm.delete.message')}
          open={isConfirmationModalOpen}
          cancelButton={{
            content: t('confirm.delete.cancel'),
          }}
          confirmButton={{
            content: t('confirm.delete.confirm'),
            negative: true,
            icon: 'trash',
            labelPosition: 'left',
          }}
          onCancel={handleProjectConfirmationModalClose}
          onConfirm={() => {
            handleDeleteProject.mutateAsync(selectedProjectId);
          }}
          size="mini"
        />
      )}
    </>
  );
}
