import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../../../services/queryClient';
import { updateScheduleHolidayService } from '../../../../../services/schedule';
import { HolidayItemProps } from '../../../../../types/schedule';

export function useHandleUpdateHoliday() {
  const { t } = useTranslation();
  const handleUpdateHoliday = useMutation(
    (data: HolidayItemProps) => {
      return updateScheduleHolidayService(data);
    },
    {
      onSuccess: () => {
        toast.success(t('generics.toast.success.edit'));
        queryClient.invalidateQueries('HOLIDAY_LIST_MANAGEMENT');
      },
      onError: () => {
        toast.error(t('generics.toast.error.edit'));
      },
    },
  );

  return {
    handleUpdateHoliday,
  };
}
