import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, Input, TextArea, Form, Icon } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../../components/Form/ControllerFormField';
import { EditableDropdown } from '../../../../../components/Form/EditableDropdown';
import { SpecialEditableDropdown } from '../../../../../components/Form/SpecialEditableDropdown';
import { Label } from '../../../../../components/Label';
import {
  createFleetTypeService,
  deleteFleetTypeService,
} from '../../../../../services/fleetTypeService';
import { FIELD_ERROR_MESSAGE } from '../../../../../settings/constants';
import { ScheduleActivityFormData } from '../../../../../types/schedule';
import { getFieldErrorMessage } from '../../../../../utils/getErrorMessage';

interface Props {
  scheduleDataRequest?: any;
  isHoliday?: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  onSubmit(data: ScheduleActivityFormData): Promise<void>;
}

export function ModalContent({
  scheduleDataRequest,
  isHoliday,
  onSubmit,
  formRef,
}: Props) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useFormContext();

  const frequenceId = useWatch({
    control,
    name: 'planningScheduleId',
  });

  const taskId = useWatch({
    control,
    name: 'activityId',
  });

  const isVacation = useMemo(() => {
    if (taskId === 8) {
      setValue('activityStatusId', undefined);
      setValue('priorityId', undefined);
      return true;
    }

    return false;
  }, [setValue, taskId]);

  const isDayOff = useMemo(() => {
    if (taskId === 48) {
      setValue('activityStatusId', undefined);
      setValue('priorityId', undefined);
      return true;
    }

    return false;
  }, [setValue, taskId]);

  const isAdministration = useMemo(() => {
    if (taskId === 41) {
      return true;
    }

    return false;
  }, [taskId]);

  return (
    <Modal.Content scrolling>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <ControllerFormField
          controllerControl={control}
          controllerName="title"
          control={Input}
          label={t('page.schedule.label.title')}
          required
          error={getFieldErrorMessage(!!errors.title, t(FIELD_ERROR_MESSAGE))}
        />

        <EditableDropdown
          controllerControl={control}
          controllerName="typeFleetId"
          label={t('page.schedule.label.business')}
          options={scheduleDataRequest.fleetTypeOptions}
          queryKey="FLEET_TYPE_QUERYKEY"
          createItemService={createFleetTypeService}
          deleteItemService={deleteFleetTypeService}
          loading={scheduleDataRequest.isLoadingFleetTypeOptions}
          required
          activateLabelError={!!errors.typeFleetId}
          error={getFieldErrorMessage(
            !!errors.typeFleetId,
            t(FIELD_ERROR_MESSAGE),
          )}
        />

        <EditableDropdown
          controllerControl={control}
          controllerName="typeActivityId"
          label={t('page.schedule.label.taskType')}
          options={scheduleDataRequest.taskTypeOptions}
          queryKey="FLEET_TYPE_QUERYKEY"
          loading={scheduleDataRequest.isLoadingScheduleData}
          required
          activateLabelError={!!errors.typeActivityId}
          error={errors.typeActivityId && t(FIELD_ERROR_MESSAGE)}
        />

        <EditableDropdown
          controllerControl={control}
          controllerName="activityId"
          label={t('page.schedule.label.taskName')}
          options={scheduleDataRequest.taskOptions}
          queryKey="FLEET_TYPE_QUERYKEY"
          loading={scheduleDataRequest.isLoadingScheduleData}
          required
          activateLabelError={!!errors.activityId}
          error={getFieldErrorMessage(
            !!errors.activityId,
            t(FIELD_ERROR_MESSAGE),
          )}
        />

        {!(isDayOff || isVacation || isAdministration) && (
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="priorityId"
            label={t('page.schedule.label.priority')}
            options={scheduleDataRequest.priorityOptions}
            required
            error={getFieldErrorMessage(
              !!errors.priorityId,
              t(FIELD_ERROR_MESSAGE),
            )}
            loading={scheduleDataRequest.isLoadingPriorityOptions}
          />
        )}

        {!(isDayOff || isVacation) && (
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="activityStatusId"
            label={t('page.schedule.label.status')}
            options={scheduleDataRequest.statusOptions}
            required
            error={getFieldErrorMessage(
              !!errors.activityStatusId,
              t(FIELD_ERROR_MESSAGE),
            )}
            loading={scheduleDataRequest.isLoadingStatusOptions}
          />
        )}

        {!(isDayOff || isVacation) && (
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="projectId"
            label={t('page.schedule.label.project')}
            options={scheduleDataRequest.projectOptions}
            loading={scheduleDataRequest.isLoadingProjectOptions}
            required
            error={getFieldErrorMessage(
              !!errors.projectId,
              t(FIELD_ERROR_MESSAGE),
            )}
          />
        )}

        {!(isDayOff || isVacation || isHoliday) && (
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="fleetId"
            label={t('page.schedule.label.fleet')}
            options={scheduleDataRequest.fleetOptions}
            loading={scheduleDataRequest.isLoadingFleetOptions}
          />
        )}

        <SpecialEditableDropdown
          type="users"
          controllerControl={control}
          controllerName="userId"
          label={t('page.schedule.label.responsible')}
          users={scheduleDataRequest.userOptions}
          loading={scheduleDataRequest.isLoadingUserOptions}
          required
          error={getFieldErrorMessage(!!errors.userId, t(FIELD_ERROR_MESSAGE))}
          activateLabelError={!!errors.userId}
        />

        <ControllerFormField
          control={Input}
          type="date"
          controllerControl={control}
          controllerName="initialDate"
          required
          label={t('page.schedule.label.plannedDate')}
          error={getFieldErrorMessage(
            !!errors.initialDate,
            t(FIELD_ERROR_MESSAGE),
          )}
        />
        {isHoliday && (
          <Label color="orange" basic>
            <Icon name="warning sign" />
            {t('page.schedule.message.warning.thisDateIsAHoliday')}
          </Label>
        )}

        <Form.Group widths="equal" unstackable>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="planningScheduleId"
            label={t('page.schedule.label.frequence')}
            required
            options={scheduleDataRequest.frequenceOptions}
            error={errors.planningScheduleId && t(FIELD_ERROR_MESSAGE)}
            loading={scheduleDataRequest.isLoadingScheduleData}
          />

          {frequenceId !== 5 && (
            <ControllerFormField
              controllerControl={control}
              controllerName="repetitions"
              control={Input}
              type="number"
              label={t('page.schedule.label.repetitions')}
              disabled={!frequenceId}
            />
          )}
        </Form.Group>

        <ControllerFormField
          controllerControl={control}
          controllerName="description"
          control={TextArea}
          label={t('page.schedule.label.description')}
        />
      </Form>
    </Modal.Content>
  );
}
