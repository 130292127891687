import { ButtonProps, Icon, SemanticICONS } from 'semantic-ui-react';

import { Container } from './styles';

interface Props extends ButtonProps {
  title: string;
  iconName?: SemanticICONS;
  outline?: boolean;
}

function Button({ title, iconName, outline, ...props }: Props) {
  return (
    <Container {...props} outline={outline}>
      {iconName && <Icon name={iconName} />}

      {title}
    </Container>
  );
}

export { Button };
