export const theme = {
  colors: {
    primary: '#004EA8',
    primary_light: '#2669B5',
    primary_dark: '#00397a',
    secondary: '#FEDA00',
    success: '',
    warning: '',
    danger: '#DB2828',
    text: '#616161',
    text_light: '#666666',
    white: '#FFFFFF',
    gray: '#333333',
    gray_light: '#22242626',
    background: '#F2F2F2',
  },
  typography: {
    family: 'Lato',
    weight: {
      regular: 400,
      bold: 700,
      black: 900,
    },
    size: {
      h1: 32,
      h2: 24,
      h3: 21,
      description: 14,
    },
  },
  spacing: {
    1: 8,
    2: 16,
    3: 24,
    4: 32,
    5: 40,
    6: 48,
    7: 56,
    8: 64,
    9: 72,
    10: 80,
    11: 88,
    12: 96,
  },
  transitions: {
    slow: 'all ease 0.4s',
    normal: 'all ease 0.3s',
    fast: 'all ease 0.2s',
  },
};

export type Colors = keyof typeof theme.colors;
export type FontSize = keyof typeof theme.typography.size;
export type FontWeight = keyof typeof theme.typography.weight;
export type Transition = keyof typeof theme.transitions;
