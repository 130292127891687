import { format } from 'date-fns';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { queryClient } from '../../../../services/queryClient';
import {
  createTireEvaluationService,
  getTireEvaluationById,
  updateTireEvaluationService,
} from '../../../../services/tireEvaluation';
import { TireEvaluationFormData } from '../../../../types/tireEvaluation';
import { FormModalContent } from './components/FormModalContent';

export function TireEvaluationFormModal() {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const { projectId, tireEvaluationId } = useParams<
    'projectId' | 'tireEvaluationId'
  >();

  const navigate = useNavigate();

  const { control, formState, handleSubmit, reset } =
    useForm<TireEvaluationFormData>({
      defaultValues: {
        id: tireEvaluationId ? Number(tireEvaluationId) : undefined,
        projectId: Number(projectId),
        statusId: 31,
      },
    });

  useEffect(() => {
    if (tireEvaluationId) {
      getTireEvaluationById(Number(tireEvaluationId)).then(data => {
        reset({
          assemblyDate: format(new Date(data.assemblyDate), 'yyyy-MM-dd'),
          fleetId: data.fleet.id,
          id: data.id ?? null,
          projectId: data.project.id,
          reports: [],
          responsibleId: data.responsible.id,
          statusId: data.status.id,
          tireQuantity: data.tireQuantity,
          tireId: data.tire.id,
        });
      });
    }
  }, [reset, tireEvaluationId]);

  const createTireEvaluation = useMutation(
    async (data: TireEvaluationFormData) => {
      return createTireEvaluationService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tireEvaluations');
        toast.success(t('page.tireEvaluations.toast.success.create'));
        navigate(-1);
      },
      onError: () => {
        toast.error(t('page.tireEvaluations.toast.error.create'));
      },
    },
  );

  const editTireEvaluation = useMutation(
    async (data: TireEvaluationFormData) => {
      return updateTireEvaluationService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tireEvaluations');
        toast.success(t('page.tireEvaluations.toast.success.edit'));
        navigate(-1);
      },
      onError: () => {
        toast.error(t('page.tireEvaluations.toast.error.edit'));
      },
    },
  );

  const onSubmit = useCallback(
    async (data: TireEvaluationFormData) => {
      if (data.id) {
        await editTireEvaluation.mutateAsync(data);
      } else {
        await createTireEvaluation.mutateAsync(data);
      }
    },
    [createTireEvaluation, editTireEvaluation],
  );

  return (
    <Modal
      open
      size="tiny"
      onClose={() => {
        navigate(-1);
      }}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {tireEvaluationId
          ? t('page.tireEvaluations.modal.edit')
          : t('page.tireEvaluations.modal.create')}
      </Modal.Header>
      <FormModalContent
        control={control}
        formRef={formRef}
        formState={formState}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      />
      <Modal.Actions>
        <ButtonGDY
          primary
          loading={formState.isSubmitting}
          disabled={formState.isSubmitting}
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
        >
          {t('page.tireEvaluations.button.save')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}
