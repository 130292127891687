import { useState } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Form, TextArea } from 'semantic-ui-react';

import { ControllerFormField } from '../../../../components/Form/ControllerFormField';
import { EditableDropdown } from '../../../../components/Form/EditableDropdown';
import { toDropdownItemProps } from '../../../../helpers/toDropdownItemProps';
import {
  createControlTypeService,
  deleteControlTypeService,
  getControlTypeService,
  updateControlTypeService,
} from '../../../../services/controlTypeService';
import {
  createCrissCrossTypeService,
  deleteCrissCrossTypeService,
  getCrissCrossTypeService,
  updateCrissCrossTypeService,
} from '../../../../services/crissCrossType';
import {
  createServiceTypeService,
  deleteServiceTypeService,
  getServiceTypeService,
  updateServiceTypeService,
} from '../../../../services/serviceTypeService';
import {
  FLEET_CONTROL_QUERYKEY,
  FLEET_CRISSCROSS_QUERYKEY,
  MAX_CHARS_TEXT_AREA,
  SERVICE_QUERYKEY,
} from '../../../../settings/constants';
import { FieldBooleanRadios } from '../../components/Form/FieldBooleanRadios';
import Routes from './components/routes';

interface IRoutesAndControlProps {
  fleetId?: number;
}

export default function RoutesAndControl({ fleetId }: IRoutesAndControlProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'routesAndControl.route',
  });

  const [currentRouteFiles, setCurrentRouteFiles] = useState<File[]>([]);
  const [oldRouteFileName, setOldRouteFileName] = useState<string[]>([]);
  const [isDeleteRouteFiles, setIsDeleteRouteFiles] = useState<boolean[]>([]);

  const { data: serviceType } = useQuery(SERVICE_QUERYKEY, async () =>
    toDropdownItemProps(await getServiceTypeService()),
  );

  const { data: controlType } = useQuery(FLEET_CONTROL_QUERYKEY, async () =>
    toDropdownItemProps(await getControlTypeService()),
  );

  const { data: crissCrossType } = useQuery(
    FLEET_CRISSCROSS_QUERYKEY,
    async () => toDropdownItemProps(await getCrissCrossTypeService()),
  );

  const crissCrossValue = useWatch({
    control,
    name: 'routesAndControl.crissCrossType',
  });

  return (
    <>
      <Form.Field>
        <label>{t('page.fleets.label.routesAndControl.routes')}</label>
        {fields.map((item, index) => (
          <Routes
            key={item.id}
            index={index}
            fleetId={fleetId}
            remove={remove}
            currentRouteFiles={currentRouteFiles}
            setCurrentRouteFiles={setCurrentRouteFiles}
            oldRouteFileName={oldRouteFileName}
            setOldRouteFileName={setOldRouteFileName}
            isDeleteRouteFiles={isDeleteRouteFiles}
            setIsDeleteRouteFiles={setIsDeleteRouteFiles}
          />
        ))}
        <Form.Button
          type="button"
          basic
          color="blue"
          content="Add"
          onClick={() => append({})}
          fluid
        />
      </Form.Field>

      <EditableDropdown
        controllerControl={control}
        controllerName="routesAndControl.services"
        label={t('page.fleets.label.routesAndControl.service')}
        multiple
        queryKey="service"
        options={serviceType}
        createItemService={createServiceTypeService}
        updateItemService={updateServiceTypeService}
        deleteItemService={deleteServiceTypeService}
      />

      <Controller
        control={control}
        name="routesAndControl.segment"
        render={({ field: { onChange, onBlur, value } }) => (
          <Form.Field
            type="text"
            maxLength={MAX_CHARS_TEXT_AREA}
            label={t('page.fleets.label.routesAndControl.segment')}
            control={TextArea}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <FieldBooleanRadios
        controllerName="routesAndControl.fixedFleetRoutes"
        radioName="fixedFleetRoutes"
        label={t('page.fleets.label.routesAndControl.fixedFleetRoutes')}
      />

      <ControllerFormField
        controllerControl={control}
        controllerName="routesAndControl.averageDistance"
        control={TextArea}
        label={t('page.fleets.label.routesAndControl.averageDistance')}
        maxLength={MAX_CHARS_TEXT_AREA}
      />

      <ControllerFormField
        controllerControl={control}
        controllerName="routesAndControl.routeSeverity"
        control={TextArea}
        label={t('page.fleets.label.routesAndControl.routeSeverity')}
        maxLength={MAX_CHARS_TEXT_AREA}
      />

      <FieldBooleanRadios
        controllerName="routesAndControl.tireControl"
        radioName="tireControl"
        label={t('page.fleets.label.routesAndControl.tireControl')}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="routesAndControl.controlTypes"
        label={t('page.fleets.label.routesAndControl.controlType')}
        queryKey="control_type"
        multiple
        options={controlType}
        createItemService={createControlTypeService}
        updateItemService={updateControlTypeService}
        deleteItemService={deleteControlTypeService}
      />

      <ControllerFormField
        controllerControl={control}
        controllerName="routesAndControl.systemName"
        control={TextArea}
        label={t('page.fleets.label.routesAndControl.systemName')}
        maxLength={MAX_CHARS_TEXT_AREA}
      />

      <FieldBooleanRadios
        controllerName="routesAndControl.haveMaintenance"
        radioName="haveMaintenance"
        label={t('page.fleets.label.routesAndControl.fleetHaveMaintenance')}
      />

      <FieldBooleanRadios
        controllerName="routesAndControl.pressureControl"
        radioName="pressureControl"
        label={t('page.fleets.label.routesAndControl.pressureControl')}
      />

      <ControllerFormField
        controllerControl={control}
        controllerName="routesAndControl.pressureUsed"
        control={TextArea}
        label={t(
          'page.fleets.label.routesAndControl.standardPressureUsedInFleet',
        )}
        maxLength={MAX_CHARS_TEXT_AREA}
      />

      <FieldBooleanRadios
        controllerName="routesAndControl.tireRotation"
        radioName="tireRotation"
        label={t('page.fleets.label.routesAndControl.crissCross')}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="routesAndControl.crissCrossType"
        label={t('page.fleets.label.routesAndControl.crissCrossType')}
        multiple
        queryKey="criss_cross_type"
        options={crissCrossType}
        createItemService={createCrissCrossTypeService}
        updateItemService={updateCrissCrossTypeService}
        deleteItemService={deleteCrissCrossTypeService}
      />

      {crissCrossValue !== undefined && (
        <ControllerFormField
          controllerControl={control}
          controllerName="routesAndControl.crissCrossObservation"
          control={TextArea}
          label={t('page.fleets.label.routesAndControl.crissCrossObservation')}
          maxLength={MAX_CHARS_TEXT_AREA}
          disabled={crissCrossValue === undefined}
        />
      )}
    </>
  );
}
