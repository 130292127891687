/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, TextArea } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../../../components/Form/ControllerFormField';
import { ControllerFormInputFile } from '../../../../../../components/Form/ControllerFormInputFile';
import { ControllerInput } from '../../../../../../components/Form/ControllerInput';
import { SpecialEditableDropdown } from '../../../../../../components/Form/SpecialEditableDropdown';
import {
  FIELD_ERROR_MESSAGE,
  MAX_CHARS_TEXT_AREA,
} from '../../../../../../settings/constants';
import { LinkReport, ProjectFormData } from '../../../../../../types/project';
import { useRequests } from '../../../../hooks/useRequests';
import { getProjectFolderMask } from '../../utils/getFolderMask';
import { ControllerFormInputMask } from '../form/ControllerFormInputMask';
import { LinkReports } from '../LinkReports';

interface IProps {
  setFocusRequestFile: React.Dispatch<React.SetStateAction<File | undefined>>;

  setIsDeleteFocusRequestFile: React.Dispatch<React.SetStateAction<boolean>>;

  isReportTasksGenerated: boolean;
  linkReports?: LinkReport[];
}

export default function ModalContent({
  setFocusRequestFile,

  setIsDeleteFocusRequestFile,

  isReportTasksGenerated,
  linkReports,
}: IProps) {
  const { t } = useTranslation();
  const { control, setValue, reset, watch, formState } =
    useFormContext<ProjectFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'linkReports',
  });

  const project = useWatch({ control });

  const {
    testTypes,
    projectStatusOptions,
    serviceTypeOptions,
    tirePositionOptions,
    users,
  } = useRequests({ isReportTasksGenerated });

  const folderMask = getProjectFolderMask(testTypes, project?.testType);

  const watchFieldArray = watch('linkReports');
  const controlledFields = fields?.map((field, index) => {
    return {
      ...field,
      // @ts-ignore
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    if (project.oldProject) {
      setValue('projectStatusId', 3);
    } else {
      reset({ ...project, projectStatusId: undefined });
    }
  }, [project.oldProject, setValue]);

  function onChangeFile(file?: File) {
    const date = new Date();

    const CURRENT_DATE_TIME = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;

    const blob = file?.slice(0, file?.size, 'image/png');

    const newFile = new File(
      [blob as Blob],
      `${file?.name
        .split('.')[0]
        .replace(/ /g, '_')}_${CURRENT_DATE_TIME}.${file?.name.split('.')[1]}`,
    );

    if (file) {
      setFocusRequestFile(newFile);
      setValue('focusRequest', newFile?.name);
    }
  }

  function onDeleteFile() {
    setIsDeleteFocusRequestFile(true);
    setFocusRequestFile(undefined);
    setValue('focusRequest', '');
  }

  return (
    <>
      <Form.Field>
        <Controller
          control={control}
          name="oldProject"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label="Does this record refer to a project that has already been completed?
              (Check this option to enable the link report field)"
              checked={value}
              onChange={(_, data) => onChange(data.checked)}
              disabled={!!project.id}
            />
          )}
        />
      </Form.Field>

      <SpecialEditableDropdown
        type="testTypes"
        controllerControl={control}
        controllerName="testType"
        label={t('page.projects.label.testType')}
        testTypes={testTypes}
        required
        error={formState.errors.testType && FIELD_ERROR_MESSAGE}
        activateLabelError={!!formState.errors.testType}
      />

      <ControllerFormInputMask
        control={control}
        formState={formState}
        folderMask={folderMask}
      />

      <Form.Group grouped>
        <ControllerFormInputFile
          control={control}
          label="Focus Request"
          watchFileName={project?.focusRequest}
          onChangeFile={onChangeFile}
          onDeleteFile={onDeleteFile}
        />
      </Form.Group>

      <ControllerFormField
        maxLength={MAX_CHARS_TEXT_AREA}
        controllerControl={control}
        controllerName="description"
        control={TextArea}
        label={t('page.projects.label.description')}
      />

      <SpecialEditableDropdown
        type="users"
        controllerControl={control}
        controllerName="responsible"
        label={t('page.projects.label.responsible')}
        users={users}
        required
        error={formState.errors.responsible && t(FIELD_ERROR_MESSAGE)}
        activateLabelError={!!formState.errors.responsible}
      />

      <ControllerFormDropdown
        controllerControl={control}
        controllerName="projectStatusId"
        label="Status"
        options={projectStatusOptions}
        disabled={!!project.oldProject}
      />

      <ControllerFormDropdown
        controllerControl={control}
        controllerName="services"
        label={t('page.projects.label.service')}
        options={serviceTypeOptions}
        required
        error={formState.errors.services && t(FIELD_ERROR_MESSAGE)}
      />

      <ControllerFormDropdown
        controllerControl={control}
        controllerName="tirePositionId"
        label={t('page.projects.label.tirePosition')}
        options={tirePositionOptions}
        required
        error={formState.errors.services && t(FIELD_ERROR_MESSAGE)}
      />

      <Form.Group grouped>
        <Form.Group unstackable widths="equal">
          <ControllerInput
            controllerControl={control}
            controllerName="started"
            fieldLabel={t('page.projects.label.startDate')}
            fieldWidth="8"
            type="date"
          />

          <ControllerInput
            controllerControl={control}
            controllerName="finished"
            fieldLabel={t('page.projects.label.finishDate')}
            fieldWidth="8"
            type="date"
          />
        </Form.Group>
      </Form.Group>

      {((project?.id && project?.scheduleCreated) ||
        project.projectStatusId === 3) && (
        <Form.Group grouped>
          <Form.Field>
            <label>Link Reports</label>
            {controlledFields.map((item, index) => (
              <LinkReports
                isOldProject={!!project.oldProject}
                projectId={project?.id}
                key={item.id}
                index={index}
                remove={remove}
                linkReports={linkReports}
              />
            ))}
            {fields.length < 4 && (
              <Form.Button
                type="button"
                basic
                color="blue"
                content="Add Link Report"
                // @ts-ignore
                onClick={() => append({})}
                fluid
              />
            )}
          </Form.Field>
        </Form.Group>
      )}
    </>
  );
}
