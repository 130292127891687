import { useTranslation } from 'react-i18next';
import { Header, Image, Label, Table } from 'semantic-ui-react';

import { IShowColumnsProps } from '../../../../../../types/search';
import { TireEvaluation } from '../../../../../../types/tireEvaluation';
import { getDateFormat } from '../../../../../../utils/getDateFormat';

interface ITableBodyProps {
  tireEvaluation: TireEvaluation;
  showColumns: IShowColumnsProps;
}

export default function TableBody({
  tireEvaluation,
  showColumns,
}: ITableBodyProps) {
  const { t } = useTranslation();

  return (
    <Table.Body className="tbody">
      <Table.Row className="tr noRowspan">
        {showColumns.folder && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.project.folder}
          </Table.Cell>
        )}
        {showColumns.description && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.project.description}
          </Table.Cell>
        )}
        {showColumns.fleet && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.fleet.name}
          </Table.Cell>
        )}
        {showColumns.fleetType && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.fleet.type?.label}
          </Table.Cell>
        )}
        {showColumns.service && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            <Label.Group>
              {tireEvaluation.fleet.services?.map(service => (
                <Label key={service.id}>{service.label}</Label>
              ))}
            </Label.Group>
          </Table.Cell>
        )}
        {showColumns.brand && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            <Label.Group>
              <Label>{tireEvaluation.tire.brand}</Label>
            </Label.Group>
          </Table.Cell>
        )}
        {showColumns.model && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.tire.model}
          </Table.Cell>
        )}
        {showColumns.size && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.tire.tireSize?.label}
          </Table.Cell>
        )}
        {showColumns.modification && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.tire.modification}
          </Table.Cell>
        )}
        {showColumns.tiresQty && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            {tireEvaluation.tireQuantity}
          </Table.Cell>
        )}
        {showColumns.responsible && (
          <Table.Cell
            className="td primaryTable"
            rowSpan={tireEvaluation.reports.length}
          >
            <Header as="h4" image>
              <Image
                src={`https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${tireEvaluation.responsible.name}`}
                rounded
                size="mini"
              />
              <Header.Content>
                {tireEvaluation.responsible.name}
                <Header.Subheader>
                  {tireEvaluation.responsible.email}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
        )}
        <Table.Cell className="th">
          {tireEvaluation.reports[0]?.percentage.label}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.avgNskMm}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.wornOut}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.kmRun}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.kmMm}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.kmMmRate}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.kmProject}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.kmProjectRate}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.reportDate}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.cycle}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.retreadIndex}
        </Table.Cell>
        <Table.Cell className="td primaryTable">
          {tireEvaluation.reports[0]?.dateIssued}
        </Table.Cell>
      </Table.Row>
      {tireEvaluation.reports
        ?.filter((_, index) => index > 0)
        .map(report => (
          <Table.Row key={report.id} className="tr">
            <Table.Cell className="th">{report.percentage.label}</Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.avgNskMm}
            </Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.wornOut}
            </Table.Cell>
            <Table.Cell className="td primaryTable">{report.kmRun}</Table.Cell>
            <Table.Cell className="td primaryTable">{report.kmMm}</Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.kmMmRate}
            </Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.kmProject}
            </Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.kmProjectRate}
            </Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.reportDate &&
                getDateFormat(report.reportDate, t('label.date'))}
            </Table.Cell>
            <Table.Cell className="td primaryTable">{report.cycle}</Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.retreadIndex}
            </Table.Cell>
            <Table.Cell className="td primaryTable">
              {report.dateIssued &&
                getDateFormat(report.dateIssued, t('label.date'))}
            </Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>
  );
}
