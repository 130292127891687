import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, Modal, ModalProps } from 'semantic-ui-react';

import { ISelectedScheduleTaskProps } from '../..';
import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { useAuth } from '../../../../contexts/auth';
import { ScheduleActivityFormData } from '../../../../types/schedule';
import { useCreateScheduleTask } from '../../hooks/useCreateScheduleTask';
import { useDeleteScheduleTask } from '../../hooks/useDeleteScheduleTask';
import { useScheduleRequests } from '../../hooks/useScheduleRequests';
import { useScheduleTaskById } from '../../hooks/useScheduleTaskById';
import useScheduleTaskPermission from '../../hooks/useScheduleTaskPermission';
import { useUpdateScheduleTask } from '../../hooks/useUpdateScheduleTask';
import { TaskCopyConfirmationModal } from '../TaskCopyConfirmationModal';
import { TaskDeleteConfirmationModal } from '../TaskDeleteConfirmationModal';
import { UploadTasksConfirmModal } from '../UploadTasksConfirmModal';
import { ModalContent } from './ModalContent';
import { JustifyHeader } from './styles';
import { onCopyScheduleTask } from './utils/onCopyScheduleTask';

type ScheduleFormModalProps = ModalProps & {
  isFormModalOpen: boolean;

  selectedScheduleTask: ISelectedScheduleTaskProps;
  setSelectedScheduleTask: React.Dispatch<
    React.SetStateAction<ISelectedScheduleTaskProps>
  >;

  selectedScheduleTaskDate?: Date;
  onScheduleFormModalClose: () => void;
};

export default function ScheduleTaskFormModal({
  isFormModalOpen,

  selectedScheduleTask,
  setSelectedScheduleTask,

  selectedScheduleTaskDate,
  onScheduleFormModalClose,
  ...rest
}: ScheduleFormModalProps) {
  const { t } = useTranslation();
  const methods = useForm<ScheduleActivityFormData>({
    defaultValues: {
      initialDate:
        selectedScheduleTaskDate &&
        format(selectedScheduleTaskDate, 'yyyy-MM-dd'),
      priorityId: selectedScheduleTask?.taskId ? undefined : 3,
      activityStatusId: selectedScheduleTask?.taskId ? undefined : 1,
    },
  });
  const formRef = useRef<HTMLFormElement>(null);
  const { user } = useAuth();

  const { userEmail, isStatusNotCompleted, taskData } = useScheduleTaskById({
    reset: methods.reset,
    scheduleTaskId: selectedScheduleTask?.taskId,
  });

  const isTaskOwner = user.mail.toLowerCase() === userEmail?.toLowerCase();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isCopyConfirmationModalOpen, setIsCopyConfirmationModalOpen] =
    useState(false);
  const [
    isUpdateDatesConfirmationModalOpen,
    setIsUpdateDatesConfirmationModalOpen,
  ] = useState(false);

  const [isUpdateMoreReports, setIsUpdateMoreReports] = useState(false);
  const [taskNewDate, setTaskNewDate] = useState<string | Date>();

  const currentInitialDate = useWatch({
    control: methods.control,
    name: 'initialDate',
  });

  const initialDate = useWatch({
    control: methods.control,
    name: 'initialDate',
  });

  const scheduleDataRequest = useScheduleRequests(methods.control);

  const isHoliday = useMemo(() => {
    return (
      scheduleDataRequest?.holidayList?.findIndex(
        (el: any) => el.holidayDate === initialDate,
      ) !== -1
    );
  }, [initialDate, scheduleDataRequest?.holidayList]);

  const currentUserId = useMemo(
    () =>
      scheduleDataRequest.userOptions?.find(
        ({ email }) => email.toLowerCase() === user?.mail.toLowerCase(),
      )?.id,
    [scheduleDataRequest.userOptions, user?.mail],
  );

  useEffect(() => {
    if (currentUserId) {
      methods.setValue('userId', currentUserId);
    }
  }, [currentUserId]);

  const scheduleTaskPermission = useScheduleTaskPermission({
    selectedTaskId: selectedScheduleTask.taskId,
    isFormModalOpen,
    isTaskStatusNotCompleted: isStatusNotCompleted,
    isTaskOwner,
  });

  function handleUpdateDatesConfirmationModalOpen() {
    setIsUpdateDatesConfirmationModalOpen(true);
  }

  const createScheduleTask = useCreateScheduleTask({
    onRequestClose: onScheduleFormModalClose,
  });
  const updateScheduleTask = useUpdateScheduleTask({
    onRequestClose: onScheduleFormModalClose,
  });
  const deleteScheduleTask = useDeleteScheduleTask({
    onRequestClose: onScheduleFormModalClose,
  });

  const onSubmit = useCallback(
    async (data: ScheduleActivityFormData) => {
      const isReportTask = data.type === 1;
      const isDifferentDates = taskData?.initialDate !== currentInitialDate;
      setTaskNewDate(data.initialDate);

      if (data.id) {
        if (isDifferentDates && isReportTask) {
          return handleUpdateDatesConfirmationModalOpen();
        }

        return updateScheduleTask.mutateAsync(data);
      }
      return createScheduleTask.mutateAsync(data);
    },
    [
      createScheduleTask,
      currentInitialDate,
      taskData?.initialDate,
      updateScheduleTask,
    ],
  );

  function handleCopyScheduleTask() {
    onCopyScheduleTask({
      methods,
      setIsCopyConfirmationModalOpen,
      setSelectedScheduleTask,
    });
  }

  return (
    <>
      <Modal
        {...rest}
        open={isFormModalOpen}
        size="tiny"
        onClose={onScheduleFormModalClose}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <JustifyHeader>
            {selectedScheduleTask?.taskId
              ? t('title.scheduleActivityConclusion')
              : t('title.scheduleActivityRegistration')}

            {isHoliday && (
              <Label color="orange">
                <Icon name="warning sign" size="small" />
                {t('page.schedule.formModal.header.holiday')}
              </Label>
            )}
          </JustifyHeader>
        </Modal.Header>

        <FormProvider {...methods}>
          <ModalContent
            formRef={formRef}
            onSubmit={onSubmit}
            scheduleDataRequest={scheduleDataRequest}
            isHoliday={isHoliday}
          />
        </FormProvider>

        {scheduleTaskPermission && (
          <Modal.Actions>
            {selectedScheduleTask?.taskId && (
              <Button
                basic
                icon="trash"
                negative
                onClick={() => setIsDeleteConfirmationModalOpen(true)}
              />
            )}
            {selectedScheduleTask?.taskId && (
              <Button
                icon="copy"
                onClick={() => setIsCopyConfirmationModalOpen(true)}
              />
            )}
            <ButtonGDY
              content={t('page.tireEvaluations.button.save')}
              onClick={() => {
                formRef.current?.handleSubmit();
              }}
            />
          </Modal.Actions>
        )}
      </Modal>

      <TaskDeleteConfirmationModal
        isConfirmationModalOpen={isDeleteConfirmationModalOpen}
        handleScheduleConfirmationModalClose={() =>
          setIsDeleteConfirmationModalOpen(false)
        }
        deleteScheduleTask={deleteScheduleTask}
        selectedScheduleTask={selectedScheduleTask}
      />

      <TaskCopyConfirmationModal
        isConfirmationModalOpen={isCopyConfirmationModalOpen}
        handleScheduleConfirmationModalClose={() =>
          setIsCopyConfirmationModalOpen(false)
        }
        handleCopyScheduleTask={handleCopyScheduleTask}
      />

      {isUpdateDatesConfirmationModalOpen && (
        <UploadTasksConfirmModal
          data={{ ...taskData, initialDate: taskNewDate }}
          handleConfirmationModalClose={() => {
            setIsUpdateDatesConfirmationModalOpen(false);
          }}
          onScheduleFormModalClose={onScheduleFormModalClose}
          isConfirmationModalOpen={isUpdateDatesConfirmationModalOpen}
          isUpdateMoreReports={isUpdateMoreReports}
          setIsUpdateMoreReports={setIsUpdateMoreReports}
        />
      )}
    </>
  );
}
