import { convertArrayDataToArrayOfObjects } from '../helpers/convertArrayDataToArrayOfObjects';
import {
  FleetDataCategorized,
  Fleet,
  FleetsResponseDataProps,
} from '../types/fleet';
import api from './api';

interface IProps {
  page?: number;
  size?: number;
  name?: string;
  region?: string;
  regionAdvisor?: string;
  type?: string;
}

export async function getFleetListService({
  page = 0,
  size = 10,
  regionAdvisor,
  type,
  name,
  region,
}: IProps): Promise<FleetsResponseDataProps> {
  const response = await api.get(`/fleets/`, {
    params: {
      regionAdvisor,
      type,
      name,
      region,
      page,
      size,
      sort: 'name,asc',
    },
  });

  return response.data;
}

export async function createFleetService({
  generalInformation,
  routesAndControl,
  vehicles,
  rubberShopAndTires,
  tireRecap,
  additionalInformation,
}: any): Promise<any> {
  const data = {
    id: null,
    ...generalInformation,
    ...routesAndControl,
    ...vehicles,
    ...rubberShopAndTires,
    ...tireRecap,
    ...additionalInformation,
  };

  convertArrayDataToArrayOfObjects(data);

  const response = await api.post('/fleets/', data);

  return response.data;
}

export async function getFleetByIdService(id?: number): Promise<Fleet> {
  const response = await api.get(`/fleets/${id}`);

  return response.data;
}

export async function updateFleetService({
  id,
  generalInformation,
  routesAndControl,
  vehicles,
  rubberShopAndTires,
  tireRecap,
  additionalInformation,
}: FleetDataCategorized): Promise<Fleet> {
  const data = {
    id,
    ...generalInformation,
    ...routesAndControl,
    ...vehicles,
    ...rubberShopAndTires,
    ...tireRecap,
    ...additionalInformation,
  } as Fleet;

  convertArrayDataToArrayOfObjects(data);

  const response = await api.put(`/fleets/`, data);

  return response.data;
}

export async function deleteFleetService(id: number): Promise<void> {
  await api.delete(`/fleets/${id}`);
}
