import {
  TireEvaluationFormData,
  TireEvaluation,
  TireEvaluationResponseDataProps,
  TireEvaluationStatus,
} from '../types/tireEvaluation';
import api from './api';

export async function getTireEvaluationListService({
  page = 0,
  size = 10,
  project,
  fleet,
}: {
  page?: number;
  size?: number;
  project?: number;
  fleet?: number;
}): Promise<TireEvaluationResponseDataProps> {
  const response = await api.get(`/tireEvaluations/`, {
    params: {
      project,
      fleet,
      page,
      size,
      sort: 'id,asc',
    },
  });

  return response.data;
}

export async function getTireEvaluationById(
  id: number,
): Promise<TireEvaluation> {
  const response = await api.get(`/tireEvaluations/${id}`);

  return response.data;
}

export async function createTireEvaluationService(
  data: TireEvaluationFormData,
): Promise<TireEvaluationFormData> {
  data = {
    ...data,
    tireQuantity: Number(data.tireQuantity),
    assemblyDate: new Date(data.assemblyDate),
    reports: [],
  };

  const response = await api.post('/tireEvaluations/', data);

  return response.data;
}

export async function updateTireEvaluationService(
  data: TireEvaluationFormData,
): Promise<TireEvaluationFormData> {
  data = {
    ...data,
    tireQuantity: Number(data.tireQuantity),
    assemblyDate: new Date(data.assemblyDate),
    reports: [],
    tirePositions: [
      {
        id: data.tirePositions as unknown as number,
      },
    ],
  };

  const response = await api.put(`/tireEvaluations/`, data);

  return response.data;
}

export async function deleteTireEvaluationService(id: number): Promise<void> {
  await api.delete(`/tireEvaluations/${id}`);
}

export async function getTireEvaluationStatusService(): Promise<TireEvaluationStatus> {
  const response = await api.get('/tireEvaluations/status');

  return response.data;
}
