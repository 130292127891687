/* eslint-disable react/no-unescaped-entities */

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Form, Input, TextArea } from 'semantic-ui-react';

import { ControllerFormField } from '../../../../components/Form/ControllerFormField';
import { EditableDropdown } from '../../../../components/Form/EditableDropdown';
import { SpecialEditableDropdown } from '../../../../components/Form/SpecialEditableDropdown';
import { toDropdownItemProps } from '../../../../helpers/toDropdownItemProps';
import useLocaleQueries from '../../../../hooks/useLocaleQueries';
import {
  createAccountFleetService,
  updateAccountFleetService,
  deleteAccountFleetService,
  getAccountFleetService,
} from '../../../../services/fleetAccountService';
import {
  createFleetTypeService,
  deleteFleetTypeService,
  getFleetTypeService,
  updateFleetTypeService,
} from '../../../../services/fleetTypeService';
import { getUserListService } from '../../../../services/user';
import {
  FIELD_ERROR_MESSAGE,
  FLEET_ACCOUNT_QUERYKEY,
  FLEET_TYPE_QUERYKEY,
  MAX_CHARS_TEXT_AREA,
  USERS_QUERYKEY,
} from '../../../../settings/constants';
import { FleetDataCategorized } from '../../../../types/fleet';
import { FleetContact } from './components/FleetContact/index';
import { ShareBusiness } from './components/ShareBusiness';

export default function GeneralInformation() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<FleetDataCategorized>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'generalInformation.fleetContact',
  });

  const { data: fleetType } = useQuery(FLEET_TYPE_QUERYKEY, async () => {
    return toDropdownItemProps(await getFleetTypeService());
  });

  const { data: users } = useQuery(USERS_QUERYKEY, async () =>
    getUserListService(),
  );

  const { data: accountType } = useQuery(FLEET_ACCOUNT_QUERYKEY, async () => {
    return toDropdownItemProps(await getAccountFleetService());
  });

  const { states, isLoadingStates, citiesByState, isLoadingCitiesByState } =
    useLocaleQueries('generalInformation.region');

  function addFleetContact() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    append({
      name: '',
      email: '',
      phone: '',
      profile: '',
      observation: '',
    });
  }

  return (
    <>
      <ControllerFormField
        controllerControl={control}
        controllerName="generalInformation.name"
        control={Input}
        label={t('page.fleets.label.generalInformation.fleetsName')}
        required
        error={errors.generalInformation?.name && t(FIELD_ERROR_MESSAGE)}
        maxLength={200}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="generalInformation.type"
        label={t('page.fleets.label.generalInformation.type')}
        options={fleetType}
        queryKey={FLEET_TYPE_QUERYKEY}
        createItemService={createFleetTypeService}
        updateItemService={updateFleetTypeService}
        deleteItemService={deleteFleetTypeService}
      />

      <Form.Group widths="equal">
        <Controller
          control={control}
          name="generalInformation.region"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Form.Field required>
              <label>{t('page.fleets.label.generalInformation.state')}</label>
              <Form.Select
                clearable
                search
                options={states ?? []}
                value={value}
                onChange={(_, data) => onChange(data.value)}
                onBlur={onBlur}
                loading={isLoadingStates}
                error={
                  errors.generalInformation?.region?.type === 'required' &&
                  t(FIELD_ERROR_MESSAGE)
                }
              />
            </Form.Field>
          )}
        />

        <Controller
          control={control}
          name="generalInformation.city"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Form.Field required disabled={!citiesByState}>
              <label>{t('page.fleets.label.generalInformation.city')}</label>
              <Form.Select
                clearable
                search
                options={citiesByState ?? []}
                value={value}
                onChange={(_, data) => onChange(data.value)}
                onBlur={onBlur}
                loading={isLoadingCitiesByState}
                error={
                  errors.generalInformation?.city?.type === 'required' &&
                  t(FIELD_ERROR_MESSAGE)
                }
              />
            </Form.Field>
          )}
        />
      </Form.Group>

      <Form.Field>
        <label>{t('page.fleets.label.generalInformation.fleetsContact')}</label>
        {fields.map((item, index) => (
          <FleetContact key={item.id} remove={remove} index={index} />
        ))}
        <Form.Button
          type="button"
          basic
          color="blue"
          content="Add"
          onClick={() => addFleetContact()}
          fluid
        />
      </Form.Field>

      <SpecialEditableDropdown
        type="users"
        controllerControl={control}
        controllerName="generalInformation.regionAdvisor"
        label={t('page.fleets.label.generalInformation.pfc')}
        users={users}
      />

      <EditableDropdown
        controllerControl={control}
        controllerName="generalInformation.account"
        label={t('page.fleets.label.generalInformation.account')}
        options={accountType}
        queryKey={FLEET_ACCOUNT_QUERYKEY}
        createItemService={createAccountFleetService}
        updateItemService={updateAccountFleetService}
        deleteItemService={deleteAccountFleetService}
      />

      <ControllerFormField
        maxLength={200}
        controllerControl={control}
        controllerName="generalInformation.shipTo"
        label={t('page.fleets.label.generalInformation.shipTo')}
        control={Input}
      />

      <ControllerFormField
        maxLength={200}
        controllerControl={control}
        controllerName="generalInformation.soldTo"
        label={t('page.fleets.label.generalInformation.soldTo')}
        control={Input}
      />

      <ControllerFormField
        type="text"
        maxLength={MAX_CHARS_TEXT_AREA}
        controllerControl={control}
        controllerName="generalInformation.subsidiaries"
        label={t('page.fleets.label.generalInformation.subsidiaries')}
        control={TextArea}
      />

      <ShareBusiness />

      <ControllerFormField
        maxLength={200}
        controllerControl={control}
        controllerName="generalInformation.competitonTest"
        label={t('page.fleets.label.generalInformation.fleetCompetitorTesting')}
        control={Input}
      />
    </>
  );
}
