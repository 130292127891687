import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'semantic-ui-react';

interface Props {
  isConfirmationModalOpen: boolean;
  handleScheduleConfirmationModalClose(): void;
  handleCopyScheduleTask(): void;
}

export function TaskCopyConfirmationModal({
  isConfirmationModalOpen,
  handleScheduleConfirmationModalClose,
  handleCopyScheduleTask,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      size="mini"
      open={isConfirmationModalOpen}
      onClose={handleScheduleConfirmationModalClose}
    >
      <Modal.Header content={t('confirm.copy.title')} />

      <Modal.Content>{t('confirm.copy.message')}</Modal.Content>

      <Modal.Actions>
        <Button
          content={t('confirm.copy.cancel')}
          onClick={handleScheduleConfirmationModalClose}
        />
        <Button
          positive
          icon="copy"
          labelPosition="left"
          content={t('confirm.copy.confirm')}
          onClick={handleCopyScheduleTask}
        />
      </Modal.Actions>
    </Modal>
  );
}
