import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]}px;

  @media (max-width: 460px) {
    gap: ${({ theme }) => theme.spacing[1]}px;
  }
`;
