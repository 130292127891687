import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import { ControllerInput } from '../../../../components/Form/ControllerInput';
import { ReportFormData } from '../../../../types/report';

interface IModalFormGroup {
  controlParams: Control<ReportFormData, object>;
}

export default function ModalFormGroup({ controlParams }: IModalFormGroup) {
  const { t } = useTranslation();

  return (
    <>
      <Form.Field>
        <Form.Group unstackable>
          <ControllerInput
            controllerControl={controlParams}
            controllerName="kmMm"
            fieldLabel={t('page.reports.label.kmMm')}
            label={{ content: 'KM' }}
            fieldWidth="8"
            labelPosition="right"
            type="number"
          />
          <ControllerInput
            controllerControl={controlParams}
            controllerName="kmMmRate"
            type="number"
            fieldLabel={t('page.reports.label.kmMm')}
            fieldWidth="8"
            label={{ content: '%' }}
            labelPosition="right"
          />
        </Form.Group>
      </Form.Field>

      <Form.Field>
        <Form.Group unstackable>
          <ControllerInput
            controllerControl={controlParams}
            controllerName="kmProject"
            type="number"
            fieldLabel={t('page.reports.label.kmProject')}
            fieldWidth="8"
            label={{ content: 'KM' }}
            labelPosition="right"
          />
          <ControllerInput
            controllerControl={controlParams}
            controllerName="kmProjectRate"
            fieldLabel={t('page.reports.label.kmProject')}
            label={{ content: '%' }}
            fieldWidth="8"
            labelPosition="right"
            type="number"
          />
        </Form.Group>
      </Form.Field>
    </>
  );
}
