import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  Button,
  Icon,
  Dropdown,
  Table as SUITable,
  Image,
  Popup,
  Label,
} from 'semantic-ui-react';

import { ButtonGDY } from '../../../../../components/Form/ButtonGDY';
import useIsMobileScreen from '../../../../../hooks/useIsMobileScreen';
import { usePermission } from '../../../../../hooks/usePermission';
import {
  TireEvaluationFormData,
  TireEvaluationResponseDataProps,
} from '../../../../../types/tireEvaluation';
import { getDateFormat } from '../../../../../utils/getDateFormat';
import { getSemanticColor } from '../../../../../utils/getSemanticColor';

interface ITableBodyProps {
  tireEvaluations?: TireEvaluationResponseDataProps;
  handleDeleteModalOpen(tireEvaluationId: number): void;
  handleDuplicateLine: (data: any) => () => Promise<void>;
}

export default function TableBody({
  tireEvaluations,
  handleDeleteModalOpen,
  handleDuplicateLine,
}: ITableBodyProps) {
  const { t } = useTranslation();
  const { isEditor } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileScreen } = useIsMobileScreen(460);

  const { handleSubmit } = useForm<TireEvaluationFormData>({});

  const btnDisabled = useCallback(
    (tireEvaluationStatusName: string, projectStatusName: string) => {
      if (
        tireEvaluationStatusName === 'PENDING' &&
        projectStatusName === 'ON GOING'
      ) {
        return false;
      }

      return true;
    },
    [],
  );

  return (
    <SUITable.Body className="tbody">
      {tireEvaluations ? (
        tireEvaluations?._embedded.tireEvaluations?.map(
          (tireEvaluation, index) => {
            return (
              <SUITable.Row className="tr noRowspan" key={tireEvaluation.id}>
                <SUITable.Cell className="td id">{index + 1}</SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.tire?.brand}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.tire?.model}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.tire?.modification}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.tire?.tireSize?.label}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation?.tireQuantity}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.fleet?.name}
                </SUITable.Cell>
                <SUITable.Cell className="td service">
                  {tireEvaluation.project?.services?.map(
                    service => service.label,
                  )}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.project?.tirePosition?.label}
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  <Header as="h4" image>
                    <Image
                      src={`https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${tireEvaluation.responsible.name}`}
                      rounded
                      size="mini"
                    />
                    <Header.Content>
                      {tireEvaluation.responsible.name}
                      <Header.Subheader>
                        {tireEvaluation.responsible.email}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </SUITable.Cell>
                <SUITable.Cell className="td">
                  {tireEvaluation.assemblyDate &&
                    getDateFormat(tireEvaluation.assemblyDate, t('label.date'))}
                </SUITable.Cell>

                <SUITable.Cell className="td">
                  <Label
                    color={getSemanticColor(tireEvaluation.status?.label)}
                    content={tireEvaluation.status.label}
                  />
                </SUITable.Cell>

                <SUITable.Cell className="td">
                  <Label.Group>
                    {tireEvaluation?.reports?.map(({ id, percentage }) => (
                      <Label
                        key={`${id} - ${percentage.label}`}
                        content={percentage.label}
                      />
                    ))}
                  </Label.Group>
                </SUITable.Cell>

                {isEditor && (
                  <SUITable.Cell className="td action">
                    <Dropdown
                      pointing="right"
                      trigger={<Icon name="ellipsis vertical" size="large" />}
                      icon={null}
                    >
                      <Dropdown.Menu>
                        <span className="action-buttons">
                          <Popup
                            disabled={isMobileScreen}
                            content="Sign in to the reports page"
                            trigger={
                              <ButtonGDY
                                icon="sign in"
                                size={isMobileScreen ? 'mini' : 'small'}
                                onClick={() =>
                                  navigate(
                                    `${location.pathname}/${tireEvaluation.id}/reports/`,
                                  )
                                }
                              />
                            }
                          />

                          <Popup
                            disabled={isMobileScreen}
                            content={t(
                              'page.tireEvaluations.tooltip.action.edit',
                            )}
                            trigger={
                              <Button
                                icon="pencil"
                                size={isMobileScreen ? 'mini' : 'small'}
                                color="yellow"
                                onClick={() => {
                                  navigate(
                                    `${location.pathname}/edit/${tireEvaluation.id}`,
                                    {
                                      state: {
                                        backgroundLocation: location,
                                      },
                                    },
                                  );
                                }}
                              />
                            }
                          />
                          <Popup
                            disabled={isMobileScreen}
                            content={t(
                              'page.tireEvaluations.tooltip.action.copy',
                            )}
                            trigger={
                              <Button
                                icon="copy"
                                size={isMobileScreen ? 'mini' : 'small'}
                                color="green"
                                onClick={handleSubmit(
                                  handleDuplicateLine(tireEvaluation),
                                )}
                              />
                            }
                          />

                          <Popup
                            disabled={isMobileScreen}
                            content={t(
                              'page.tireEvaluations.tooltip.action.delete',
                            )}
                            trigger={
                              <Button
                                icon="trash"
                                size={isMobileScreen ? 'mini' : 'small'}
                                color="red"
                                onClick={() => {
                                  handleDeleteModalOpen(tireEvaluation.id);
                                }}
                              />
                            }
                          />

                          <Popup
                            content="Inspection tasks for these tires have already been generated."
                            disabled={
                              !btnDisabled(
                                tireEvaluation.status.label,
                                tireEvaluation.project.projectStatus.label,
                              ) || isMobileScreen
                            }
                          />
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </SUITable.Cell>
                )}
              </SUITable.Row>
            );
          },
        )
      ) : (
        <SUITable.Row>
          <SUITable.Cell colSpan="20">
            <Header>{t('error.noDataFound')}</Header>
          </SUITable.Cell>
        </SUITable.Row>
      )}
    </SUITable.Body>
  );
}
