/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, PaginationProps, Table as TableSUI } from 'semantic-ui-react';

import { IShowColumnsProps } from '../..';
import { Pagination } from '../../../../components/Pagination';
import { SearchResponseDataProps } from '../../../../types/search';
import {
  mainTableHeaderCells,
  reportTableHeaderCells,
} from '../../utils/columns';
import TableBody from './components/TableBody';

interface ITableProps {
  showColumns: IShowColumnsProps;
  searchData?: SearchResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export function SecondaryTable({
  showColumns,
  searchData,
  page,
  setPage,
}: ITableProps) {
  const { t } = useTranslation();

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) {
    setPage(activePage as number);
  }

  const maxReportsLength = useMemo(() => {
    let maxReports = 0;

    searchData?._embedded.tireEvaluations?.forEach(({ reports }) => {
      maxReports = maxReports < reports.length ? reports.length : maxReports;
    });

    return maxReports;
  }, [searchData?._embedded.tireEvaluations]);

  const headerCellQty =
    mainTableHeaderCells.length +
    reportTableHeaderCells.length * maxReportsLength;

  return (
    <span>
      <div className="table__scrollable secondary-table">
        <TableSUI className="table secondary-table" unstackable>
          <TableSUI.Header className="thead secondary-table">
            <TableSUI.Row className="tr secondary-table">
              {mainTableHeaderCells.map(mainHeadercell => {
                // @ts-ignore
                if (showColumns[mainHeadercell.value]) {
                  return (
                    <TableSUI.HeaderCell
                      key={mainHeadercell.key}
                      className="th secondary-table"
                      content={mainHeadercell.name}
                    />
                  );
                }
                return undefined;
              })}

              {Array(maxReportsLength)
                .fill('ReportCells')
                .map(() =>
                  reportTableHeaderCells.map(reportHeadercell => (
                    <TableSUI.HeaderCell
                      key={reportHeadercell.key}
                      className="th secondary-table"
                      content={reportHeadercell.name}
                    />
                  )),
                )}
            </TableSUI.Row>
          </TableSUI.Header>

          {searchData ? (
            searchData?._embedded.tireEvaluations?.map(tireEvaluation => (
              <TableBody
                key={tireEvaluation.id}
                maxReportsLength={maxReportsLength}
                tireEvaluation={tireEvaluation}
                showColumns={showColumns}
              />
            ))
          ) : (
            <TableSUI.Body>
              <TableSUI.Row>
                <TableSUI.Cell colSpan={headerCellQty}>
                  <Header>{t('error.noDataFound')}</Header>
                </TableSUI.Cell>
              </TableSUI.Row>
            </TableSUI.Body>
          )}
        </TableSUI>
      </div>

      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={searchData?.page.totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </span>
  );
}
