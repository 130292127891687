import { AxiosError } from 'axios';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { deleteFileService } from '../../../../../../../services/fileService';
import { queryClient } from '../../../../../../../services/queryClient';

interface IProps {
  index: number;

  oldRouteFileName: string[];

  currentRouteFiles: File[];
  setCurrentRouteFiles: (value: React.SetStateAction<File[]>) => void;

  isDeleteRouteFiles: boolean[];
  setIsDeleteRouteFiles: (value: React.SetStateAction<boolean[]>) => void;
}

export function useDeleteFile({
  index,

  oldRouteFileName,

  currentRouteFiles,
  setCurrentRouteFiles,

  isDeleteRouteFiles,
  setIsDeleteRouteFiles,
}: IProps) {
  const { control, setValue } = useFormContext();

  const watchRouteFileName = useWatch({
    control,
    name: `routesAndControl.route.${index}.fileRoute`,
  });

  return useMutation(
    async () => {
      const currentRouteFilesAux = [...currentRouteFiles];
      currentRouteFilesAux[index] = {} as File;
      setCurrentRouteFiles(currentRouteFilesAux);

      const isDeleteRouteFilesAux = [...isDeleteRouteFiles];
      isDeleteRouteFilesAux[index] = true;
      setIsDeleteRouteFiles(isDeleteRouteFilesAux);
      setValue(`routesAndControl.route.${index}.fileRoute`, '');

      return deleteFileService(
        oldRouteFileName?.[index] ?? watchRouteFileName,
        'FLEET/',
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ROUTE_FILE');

        setValue(`routesAndControl.route.${index}.fileRoute`, '');

        toast.success('File deleted successfully');
      },
      onError: (error: AxiosError<any>) => {
        toast.error(error.response?.data?.message);
      },
    },
  );
}
