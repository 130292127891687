import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  BreadcrumbSectionProps,
  Confirm,
  Loader,
} from 'semantic-ui-react';

import { Page } from '../../components/Container';
import { Toolbar } from '../../components/Toolbar';
import { queryClient } from '../../services/queryClient';
import {
  deleteReportService,
  getReportListService,
} from '../../services/report';
import { getTireEvaluationById } from '../../services/tireEvaluation';
import {
  BASE_URL,
  DATE_FORMAT,
  REPORTS_QUERYKEY,
} from '../../settings/constants';
import { Report, ReportFormData } from '../../types/report';
import { TireEvaluationLabelStatus } from '../TireEvaluations/components/TireEvaluationLabelStatus';
import { useFetchProjectById } from '../TireEvaluations/hooks/useFetchProjectById';
import { ReportFormModal } from './components/ReportFormModal';
import { Table } from './components/Table/index';

type ReportParams =
  | 'projectPage'
  | 'projectId'
  | 'tireEvaluationPage'
  | 'tireEvaluationId';

export function Reports() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projectPage, projectId, tireEvaluationPage, tireEvaluationId } =
    useParams<ReportParams>();
  const { isProjectLoading, project } = useFetchProjectById(Number(projectId));
  const [tireSeleted, setTireSelected] = useState<string>();

  const sections: BreadcrumbSectionProps[] | undefined = [
    {
      key: 'projects',
      content: t('title.projects'),
      link: true,
      onClick: () => navigate(`${BASE_URL}projects/${projectPage}`),
    },
    {
      key: 'tireEvaluation',
      content: (
        <>
          <span>{project?.folder}</span>
          <TireEvaluationLabelStatus
            isProjectLoading={isProjectLoading}
            project={project}
          />
        </>
      ),
      link: true,
      onClick: () =>
        navigate(
          `${BASE_URL}projects/${projectPage}/${projectId}/tire-evaluation/${tireEvaluationPage}`,
        ),
      active: true,
    },
    {
      key: 'tire',
      content: tireSeleted,
      active: true,
    },
  ];

  const [page, setPage] = useState(1);

  const [isReportFormModalOpen, setReportFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportFormData>();

  useEffect(() => {
    getTireEvaluationById(Number(tireEvaluationId)).then(res => {
      setTireSelected(
        `${res.tire.brand} ${res.tire.model} | ${res.tire.tireSize?.label} | ${res.tire.modification}`,
      );
    });
  }, []);

  const { data: reports, isLoading: isReportsLoading } = useQuery(
    [REPORTS_QUERYKEY, { tireEvaluationId, page }],
    async () => {
      const response = await getReportListService({
        page: page - 1,
        tireEvaluationId: Number(tireEvaluationId),
      });

      return {
        ...response,
        reports: {
          tireEvaluationId: tireEvaluationId || null,
        },
      };
    },
    {
      retry: false,
    },
  );

  function handleReportFormModalOpen(report?: Report) {
    if (report) {
      setSelectedReport({
        ...report,
        percentageId: report.percentage.id,
        started:
          report.started && format(new Date(report.started), DATE_FORMAT),
        finished:
          report.finished && format(new Date(report.finished), DATE_FORMAT),
        reportDate:
          report.reportDate && format(new Date(report.reportDate), DATE_FORMAT),
        dateIssued:
          report.dateIssued && format(new Date(report.dateIssued), DATE_FORMAT),
      });
    }
    setReportFormModalOpen(true);
  }

  function handleReportFormModalClose() {
    setSelectedReport(undefined);
    setReportFormModalOpen(false);
  }

  function handleReportDeleteModalOpen(report: Report) {
    setSelectedReport(report);
    setConfirmationModalOpen(true);
  }

  function handleReportDeleteModalClose() {
    setSelectedReport(undefined);
    setConfirmationModalOpen(false);
  }

  const handleDeleteReport = useMutation(
    async (id: number) => {
      const response = await deleteReportService(id);

      handleReportDeleteModalClose();

      return response;
    },
    {
      onSuccess: () => {
        toast.success('Slot deleted successfully');
        queryClient.invalidateQueries(REPORTS_QUERYKEY);
      },
      onError: () => {
        toast.error('Error deleting report');
      },
    },
  );

  return (
    <>
      <Page>
        <div className="page__title">
          {t('title.reports')}
          <div>
            <Breadcrumb
              icon="right angle"
              sections={sections as any}
              size="small"
            />
          </div>
        </div>
        <Toolbar
          onAddClick={() => handleReportFormModalOpen()}
          onApplyClick={() => undefined}
          enableFilterButton={false}
        />

        {isReportsLoading ? (
          <Loader size="large" active />
        ) : (
          <Table
            reports={reports}
            page={page}
            setPage={setPage}
            handleReportFormModalOpen={handleReportFormModalOpen}
            handleReportDeleteModalOpen={handleReportDeleteModalOpen}
          />
        )}
      </Page>

      {isReportFormModalOpen && (
        <ReportFormModal
          isOpen={isReportFormModalOpen}
          initialValues={selectedReport}
          onRequestClose={handleReportFormModalClose}
        />
      )}

      {isConfirmationModalOpen && selectedReport && (
        <Confirm
          header={t('confirm.delete.title')}
          content={t('confirm.delete.message')}
          open={isConfirmationModalOpen}
          cancelButton={{
            content: t('confirm.delete.cancel'),
          }}
          confirmButton={{
            content: t('confirm.delete.confirm'),
            negative: true,
            icon: 'trash',
            labelPosition: 'left',
          }}
          onCancel={handleReportDeleteModalClose}
          onConfirm={() => {
            handleDeleteReport.mutateAsync(selectedReport.id as number);
          }}
          size="mini"
        />
      )}
    </>
  );
}
