import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../services/queryClient';
import { createScheduleTaskService } from '../../../services/schedule';
import { SCHEDULE_TASKS } from '../../../settings/constants';
import { ScheduleActivityFormData } from '../../../types/schedule';

interface Props {
  onRequestClose(): void;
}

export function useCreateScheduleTask({ onRequestClose }: Props) {
  const { t } = useTranslation();

  return useMutation(
    async (data: ScheduleActivityFormData) => {
      return createScheduleTaskService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SCHEDULE_TASKS);
        toast.success(t('page.schedule.toast.success.create'));

        onRequestClose();
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error(t('page.schedule.toast.error.create'));
        }
      },
    },
  );
}
