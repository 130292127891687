import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/Container';
import { DepartamentOverview } from './components/DashboardChartSession/DepartamentOverview';
import { EngineersOverview } from './components/DashboardChartSession/EngineersOverview';
import { FleetsOverview } from './components/DashboardChartSession/FleetsOverview';
import { MacroOverview } from './components/DashboardChartSession/MacroOverview';
import { ProjectsOverview } from './components/DashboardChartSession/ProjectsOverview';
import { ScheduleOverview } from './components/DashboardChartSession/ScheduleOverview';
import { useChartData } from './hooks/useChartData';
import { DashboardContainer } from './styles';

export interface IsOpenOverviewProps {
  departament: boolean;
  projects: boolean;
  schedule: boolean;
  fleets: boolean;
  engineers: boolean;
}

export type DashboardOverview =
  | 'departament'
  | 'projects'
  | 'schedule'
  | 'fleets'
  | 'engineers';

export function Dashboard() {
  const { t } = useTranslation();
  const [isOpenOverview, setIsOpenOverview] = useState<IsOpenOverviewProps>({
    departament: false,
    projects: false,
    schedule: false,
    fleets: false,
    engineers: false,
  });

  function toggleOpenOverview(overviewName: DashboardOverview) {
    setIsOpenOverview(prev => ({
      ...prev,
      [overviewName]: !prev[overviewName],
    }));
  }

  const overviewData = useChartData(isOpenOverview);

  return (
    <Page>
      <div className="page__title">{t('title.dashboard')}</div>

      <DashboardContainer>
        <MacroOverview chardData={overviewData.macro} />

        <DepartamentOverview
          toggleOpen={toggleOpenOverview}
          dataChart={overviewData.departament}
        />

        <ProjectsOverview
          toggleOpen={toggleOpenOverview}
          dataChart={overviewData.projects}
        />

        <ScheduleOverview
          toggleOpen={toggleOpenOverview}
          dataChart={overviewData.schedule}
        />

        <FleetsOverview
          toggleOpen={toggleOpenOverview}
          dataChart={overviewData.fleets}
        />

        <EngineersOverview
          toggleOpen={toggleOpenOverview}
          dataChart={overviewData.engineers}
        />
      </DashboardContainer>
    </Page>
  );
}
