import { useTranslation } from 'react-i18next';

import { DashboardOverview } from '../..';
import {
  backgroundTriadColors,
  backgroundAnalogousColors,
} from '../../../../settings/constants';
import { DoughnutChart } from '../Charts';
import { ChartsBox } from '../ChartsBox';

interface IProps {
  toggleOpen(value: DashboardOverview): void;
  dataChart: any;
}

export function DepartamentOverview({ toggleOpen, dataChart }: IProps) {
  const { t } = useTranslation();

  return (
    <ChartsBox
      overviewName="departament"
      title={t('page.dashboard.department.title')}
      toggleOpen={toggleOpen}
    >
      <DoughnutChart
        title={t('page.dashboard.department.chart.projectsPerMacroTestType')}
        data={dataChart.projectsPerTestTypeJoinned}
        isLoading={dataChart.isLoadingProjectsPerTestTypeJoinned}
        backgroundColor={backgroundTriadColors}
      />

      <DoughnutChart
        title={t('page.dashboard.department.chart.projectsPerTestType')}
        data={dataChart.projectsPerTestType}
        isLoading={dataChart.isLoadingProjectsPerTestType}
        backgroundColor={backgroundAnalogousColors}
      />

      <DoughnutChart
        title={t('page.dashboard.department.chart.tiresPerMacroTestType')}
        data={dataChart.tiresPerTestTypeJoinned}
        isLoading={dataChart.isLoadingTiresPerTestTypeJoinned}
        backgroundColor={backgroundTriadColors}
      />

      <DoughnutChart
        title={t('page.dashboard.department.chart.tiresPerTestType')}
        data={dataChart.tiresPerTestType}
        isLoading={dataChart.isLoadingTiresPerTestType}
        backgroundColor={backgroundAnalogousColors}
      />
    </ChartsBox>
  );
}
