import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Content } from './Content';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

export default function Layout() {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(oldValue => !oldValue);

  return (
    <>
      <Header handleShow={handleShow} />

      <Sidebar handleShow={handleShow} show={show} setShow={setShow} />

      <Content>
        <Outlet />
      </Content>
    </>
  );
}
