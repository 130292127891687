import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Image,
  Label,
  PaginationProps,
  Popup,
  Table as SemanticUITable,
} from 'semantic-ui-react';

import { Pagination } from '../../../../components/Pagination';
import useIsMobileScreen from '../../../../hooks/useIsMobileScreen';
import { usePermission } from '../../../../hooks/usePermission';
import { FleetsResponseDataProps } from '../../../../types/fleet';

interface ITableProps {
  fleets?: FleetsResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleFleetDetailsModalOpen(fleetId: number): void;
  handleFleetFormModalOpen(fleetId: number): void;
  handleFleetDeleteModalOpen(fleetId: number): void;
}

export function Table({
  fleets,
  page,
  setPage,
  handleFleetDetailsModalOpen,
  handleFleetFormModalOpen,
  handleFleetDeleteModalOpen,
}: ITableProps) {
  const { t } = useTranslation();
  const { isEditor } = usePermission();
  const { isMobileScreen } = useIsMobileScreen(460);

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) {
    setPage(activePage as number);
  }

  return (
    <span>
      <div className="table__scrollable">
        <SemanticUITable className="table" unstackable striped>
          <SemanticUITable.Header className="thead">
            <SemanticUITable.Row className="tr">
              <SemanticUITable.HeaderCell className="th id" scope="col">
                Id
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th fleetsName" scope="col">
                {t('page.fleets.label.generalInformation.fleetsName')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th type" scope="col">
                {t('page.fleets.label.generalInformation.type')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th account" scope="col">
                {t('page.fleets.label.generalInformation.account')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th state" scope="col">
                {t('page.fleets.label.generalInformation.state')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th service" scope="col">
                {t('page.fleets.label.routesAndControl.service')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th quantity" scope="col">
                {t('page.fleets.label.vehicles.quantity')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th brand" scope="col">
                {t('page.fleets.label.vehicles.brand')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell
                className="th tireBrandsUsed"
                scope="col"
              >
                {t('page.fleets.label.tireShop.tireBrandsUsed')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell
                className="th retreadBrand"
                scope="col"
              >
                {t('page.fleets.label.tireRecap.retreadBrand')}
              </SemanticUITable.HeaderCell>
              <SemanticUITable.HeaderCell className="th pfc" scope="col">
                {t('page.fleets.label.generalInformation.pfc')}
              </SemanticUITable.HeaderCell>

              {isEditor && (
                <SemanticUITable.HeaderCell className="th action" scope="col">
                  <Icon name="cog" size="large" />
                </SemanticUITable.HeaderCell>
              )}
            </SemanticUITable.Row>
          </SemanticUITable.Header>
          <SemanticUITable.Body className="tbody">
            {fleets ? (
              fleets?._embedded.fleets?.map((fleet, index) => (
                <SemanticUITable.Row
                  className="tr noRowspan"
                  key={fleet.id}
                  onClick={() => {
                    handleFleetDetailsModalOpen(fleet.id);
                  }}
                >
                  <SemanticUITable.Cell className="td id">
                    {index + 1}
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td fleetName">
                    {fleet?.name}
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td fleetType">
                    {fleet.type?.label}
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td account">
                    {fleet.account?.label}
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td region">
                    {fleet?.region}
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td service">
                    <Label.Group size="small">
                      {fleet.services?.map(service => (
                        <Label key={service.id} content={service.label} />
                      ))}
                    </Label.Group>
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td numberVehicles">
                    {fleet.numberVehicles?.map(
                      numberVehicle => numberVehicle.label,
                    )}
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td truckBrands">
                    <Label.Group size="small">
                      {fleet.truckBrands?.map(truckBrand => (
                        <Label key={truckBrand.id} content={truckBrand.label} />
                      ))}
                    </Label.Group>
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td tireBrands">
                    <Label.Group size="small">
                      {fleet.tireBrands?.map(tireBrands => (
                        <Label key={tireBrands.id} content={tireBrands.label} />
                      ))}
                    </Label.Group>
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td usedBands">
                    <Label.Group size="small">
                      {fleet.usedBands?.map(usedBands => (
                        <Label key={usedBands.id} content={usedBands.label} />
                      ))}
                    </Label.Group>
                  </SemanticUITable.Cell>

                  <SemanticUITable.Cell className="td regionAdvisor">
                    {fleet.regionAdvisor?.name && (
                      <Header as="h4">
                        <Image
                          src={`https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${fleet.regionAdvisor.name}`}
                          rounded
                          size="mini"
                        />
                        <Header.Content>
                          {fleet.regionAdvisor?.name}
                          <Header.Subheader>
                            {fleet.regionAdvisor?.email}
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    )}
                  </SemanticUITable.Cell>

                  {isEditor && (
                    <SemanticUITable.Cell className="td action">
                      <div>
                        <Dropdown
                          pointing="right"
                          trigger={
                            <Icon name="ellipsis vertical" size="large" />
                          }
                          icon={null}
                        >
                          <Dropdown.Menu>
                            <span className="action-buttons">
                              <Popup
                                disabled={isMobileScreen}
                                content="Edit"
                                trigger={
                                  <Button
                                    icon="pencil"
                                    size="mini"
                                    color="yellow"
                                    onClick={() => {
                                      handleFleetFormModalOpen(fleet.id);
                                    }}
                                  />
                                }
                              />
                              <Popup
                                disabled={isMobileScreen}
                                content="Delete"
                                trigger={
                                  <Button
                                    icon="trash"
                                    size="mini"
                                    color="red"
                                    onClick={() => {
                                      handleFleetDeleteModalOpen(fleet.id);
                                    }}
                                  />
                                }
                              />
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </SemanticUITable.Cell>
                  )}
                </SemanticUITable.Row>
              ))
            ) : (
              <SemanticUITable.Row>
                <SemanticUITable.Cell colSpan="20">
                  <Header>{t('error.noDataFound')}</Header>
                </SemanticUITable.Cell>
              </SemanticUITable.Row>
            )}
          </SemanticUITable.Body>
        </SemanticUITable>
      </div>

      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={fleets?.page.totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </span>
  );
}
