import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Modal, ModalProps, Form } from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import {
  deleteFileService,
  uploadFileService,
} from '../../../../services/fileService';
import { getLinkReportByProjectIdService } from '../../../../services/linkReportService';
import {
  createProjectService,
  getProjectByIdService,
  updateProjectService,
} from '../../../../services/project';
import { queryClient } from '../../../../services/queryClient';
import { DATE_FORMAT } from '../../../../settings/constants';
import { ProjectFormData } from '../../../../types/project';
import ModalContent from './components/ModalContent';

type ProjectFormModalProps = ModalProps & {
  isOpen: boolean;
  projectId?: number;
  onRequestClose: () => void;
};

export function ProjectFormModal({
  isOpen,
  projectId,
  onRequestClose,
  ...rest
}: ProjectFormModalProps) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const methods = useForm<ProjectFormData>({
    defaultValues: { projectStatusId: projectId ? undefined : 1 },
  });

  const [focusRequestFile, setFocusRequestFile] = useState<File>();
  const [focusRequestFileName, setFocusRequestFileName] = useState<string>();
  const [isDeleteFocusRequestFile, setIsDeleteFocusRequestFile] =
    useState(false);

  const [isReportTasksGenerated, setIsReportTasksGenerated] = useState(false);

  const { data: linkReports } = useQuery(
    'LINK_REPORTS',
    async () => getLinkReportByProjectIdService(projectId as number),
    { enabled: !!projectId },
  );

  useEffect(() => {
    if (projectId) {
      getProjectByIdService(Number(projectId)).then(data => {
        setFocusRequestFileName(data.focusRequest);

        setIsReportTasksGenerated(!!data.scheduleCreated);

        methods.reset({
          id: data.id,
          oldProject: data.oldProject,
          testType: data.testType?.id as number,
          folder: data.folder,
          focusRequest: data.focusRequest,
          description: data.description,
          responsible: data.responsible?.id as number,
          scheduleCreated: data.scheduleCreated,
          projectStatusId: Number(data.projectStatus?.id),
          services: data.services?.[0]?.id as any,
          tirePositionId: data.tirePosition?.id,
          started: data.started && format(new Date(data.started), DATE_FORMAT),
          finished:
            data.finished && format(new Date(data.finished), DATE_FORMAT),
          linkReports: linkReports?.map(linkReport => ({
            estimatedDate: format(
              new Date(linkReport.estimatedDate),
              DATE_FORMAT,
            ),
            id: linkReport.id,
            link: linkReport.link,
            percentageReportId: linkReport.percentageReport.id,
            statusId: linkReport.status.id,
          })),
        });
      });
    }
  }, [methods.reset, projectId, linkReports]);

  const onUploadFile = useMutation(
    async () => {
      return uploadFileService(focusRequestFile as File, 'project');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('FOCUS_REQUEST_FILE');
      },
      onError: (error: AxiosError<any>) => {
        toast.error(error.response?.data?.message);
      },
    },
  );

  const onDeleteFile = useMutation(
    async () => {
      return deleteFileService(focusRequestFileName as string, 'project/');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('FOCUS_REQUEST_FILE');
        console.log('File deleted successfully');

        methods.setValue('focusRequest', '');
      },
      onError: (error: AxiosError<any>) => {
        toast.error(error.response?.data?.message);
      },
    },
  );

  const createProject = useMutation(
    async (data: ProjectFormData) => {
      if (data.linkReports) {
        data.linkReports = data.linkReports.map(link => ({
          id: undefined,
          projectId: undefined,
          ...link,
        }));
      }

      return createProjectService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('projects');
        onRequestClose();
        toast.success('Project created successfully');
      },
      onError: (error: any) => {
        if (error.response?.status === 500) {
          toast.error('This project already exists');
        } else {
          toast.error('Error creating project');
        }
      },
    },
  );

  const editProject = useMutation(
    async (data: ProjectFormData) => {
      return updateProjectService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('projects');
        onRequestClose();
        toast.success('Project updated successfully');
      },
      onError: (error: any) => {
        if (error.response?.status === 500) {
          toast.error('Error updating project');
        } else {
          toast.error(error.response?.data.error.message);
        }
      },
    },
  );

  const onSubmit = useCallback(
    async (data: ProjectFormData) => {
      if (focusRequestFile) {
        await onUploadFile.mutateAsync();
      }

      if (isDeleteFocusRequestFile && focusRequestFileName) {
        await onDeleteFile.mutateAsync();
      }

      if (projectId) {
        await editProject.mutateAsync(data);
      } else {
        await createProject.mutateAsync(data);
      }
    },
    [
      createProject,
      editProject,
      focusRequestFile,
      focusRequestFileName,
      isDeleteFocusRequestFile,
      onDeleteFile,
      onUploadFile,
      projectId,
    ],
  );

  return (
    <Modal
      {...rest}
      open={isOpen}
      size="tiny"
      onClose={onRequestClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{t('title.projectRegistration')}</Modal.Header>

      <Modal.Content scrolling>
        <FormProvider {...methods}>
          <Form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalContent
              setFocusRequestFile={setFocusRequestFile}
              setIsDeleteFocusRequestFile={setIsDeleteFocusRequestFile}
              isReportTasksGenerated={isReportTasksGenerated}
              linkReports={linkReports}
            />
          </Form>
        </FormProvider>
      </Modal.Content>

      <Modal.Actions>
        <ButtonGDY
          onClick={() => formRef.current?.handleSubmit()}
          primary
          loading={methods.formState.isSubmitting}
          disabled={methods.formState.isSubmitting}
        >
          {t('page.projects.button.save')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}
