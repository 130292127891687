/* eslint-disable react/no-unescaped-entities */

import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';

import { getValuesFromArrayOfObjects } from '../../../../helpers/getValuesFromArrayOfObjects';
import { FleetRubberShopAndTires } from '../../../../types/fleet';
import { Article } from '../components/Article';
import { Header } from '../components/Header';

interface IProps {
  data?: FleetRubberShopAndTires;
}

export function TireShop({ data }: IProps) {
  const { t } = useTranslation();

  return (
    <Article>
      <div className="table-container">
        <Table celled unstackable compact>
          <Table.Header colspan="5">
            <Table.Row>
              <Table.Cell className="title" colspan="4">
                <Header icon="dot circle" content="Tire Shop" />
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {t('page.fleets.label.tireShop.tireBrandsUsed')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="3">
                {getValuesFromArrayOfObjects(data?.tireBrands)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireShop.totalAverageKmMonth')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="3">
                {getValuesFromArrayOfObjects(data?.tireAveragesKm)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireShop.averageKmMm')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {data?.mediaKmMm}
              </Table.Cell>

              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireShop.cpk')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="3">
                {data?.cpk}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireShop.competitorPerfomance')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="3">
                {data?.competitorPerfomance}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireShop.fleetTiresSize')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="3">
                {getValuesFromArrayOfObjects(data?.tireSize)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.tireShop.tireOutServiceReasons')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colspan="3">
                {data?.tireExitReason}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Article>
  );
}
