import styled from 'styled-components';

const HEADER_HEIGHT = '56px';
const SIDEBAR_WIDTH = '63px';

export const Container = styled.div`
  padding-top: calc(${HEADER_HEIGHT} + ${({ theme }) => theme.spacing[2]}px);
  padding-right: ${({ theme }) => theme.spacing[2]}px;
  padding-bottom: 0;
  padding-left: calc(${SIDEBAR_WIDTH} + ${({ theme }) => theme.spacing[2]}px);

  @media (max-width: 460px) {
    padding-top: calc(${HEADER_HEIGHT} + ${({ theme }) => theme.spacing[1]}px);
    padding-right: ${({ theme }) => theme.spacing[1]}px;
    padding-bottom: 0;
    padding-left: ${({ theme }) => theme.spacing[1]}px;
  }
`;
