import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { uploadFileService } from '../../../../../../../services/fileService';
import { queryClient } from '../../../../../../../services/queryClient';

interface IProps {
  index: number;

  currentRouteFiles: File[];
}

export function useUploadFile({
  index,

  currentRouteFiles,
}: IProps) {
  return useMutation(
    async () => {
      return uploadFileService(currentRouteFiles[index], 'FLEET');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ROUTE_FILE');
        toast.success('File uploaded successfuly');
      },
      onError: (error: AxiosError<any>) => {
        toast.error(error.response?.data?.message);
      },
    },
  );
}
