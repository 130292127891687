import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Modal,
  StrictFormDropdownProps,
} from 'semantic-ui-react';

import { toDropdownItemProps } from '../../../helpers/toDropdownItemProps';
import { usePermission } from '../../../hooks/usePermission';
import { TestType, StatusColor } from '../../../types/project';
import { Tire } from '../../../types/tire';
import { User } from '../../../types/user';
import { TestTypeModalContent } from './components/TestTypeModalContent';
import { TireContent } from './components/TireModalContent';
import { UserModalContent } from './components/UserModalContent';
import { Container } from './styles';

type Props = Omit<StrictFormDropdownProps, 'control' | 'error'> & {
  controllerControl: Control<any>;
  controllerName: string;
  label: string;
  loading?: boolean;
  statusColors?: StatusColor[];

  type: 'testTypes' | 'users' | 'tires';
  testTypes?: TestType[];
  users?: User[];
  tires?: Tire[];

  required?: boolean;
  activateLabelError?: boolean;
  error?: any;
};

export function SpecialEditableDropdown({
  controllerControl,
  controllerName,
  label,
  statusColors,

  type,
  testTypes,
  users,
  tires,

  required,
  activateLabelError,

  ...rest
}: Props) {
  const { t } = useTranslation();
  const { isAdmin } = usePermission();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const options = toDropdownItemProps(
    users ?? tires ?? testTypes ?? statusColors,
  );

  function handleModalOpen() {
    setIsModalOpen(true);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  function getModalContent() {
    if (type === 'testTypes') {
      return <TestTypeModalContent testTypes={testTypes} />;
    }
    if (type === 'users') {
      return <UserModalContent users={users} />;
    }
    if (type === 'tires') {
      return <TireContent tires={tires} />;
    }

    return null;
  }

  return (
    <Container>
      <Form.Group grouped>
        <Form.Field
          label={label}
          required={required}
          error={activateLabelError}
        />

        <Form.Group unstackable widths="equal" inline>
          <Controller
            control={controllerControl}
            name={controllerName}
            rules={{
              required,
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                style={{ marginRight: isAdmin ? '1em' : '0' }}
                clearable
                search
                selection
                value={value}
                onChange={(_, data) => onChange(data.value)}
                options={options}
                {...rest}
              />
            )}
          />
          {isAdmin && (
            <Button type="button" icon onClick={handleModalOpen}>
              <Icon name="edit" />
            </Button>
          )}
        </Form.Group>
      </Form.Group>

      {isAdmin && (
        <Modal
          open={isModalOpen}
          onCancel={handleModalClose}
          onConfirm={handleModalClose}
        >
          <Modal.Header
            content={t('component.specialEditableDropdown.manageList')}
          />
          <Modal.Content>{getModalContent()}</Modal.Content>
          <Modal.Actions>
            <Button onClick={handleModalClose}>
              <Icon name="checkmark" />
              {t('component.specialEditableDropdown.button.done')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Container>
  );
}
