import { useTranslation } from 'react-i18next';

import { DashboardOverview } from '../..';
import { FleetsOverviewDataProps } from '../../../../types/dashboard';
import { BasicHorBarChart } from '../Charts/BasicHorBarChart';
import { StackedBarChart } from '../Charts/StackedBarChart';
import { ChartsBox } from '../ChartsBox';

interface IProps {
  toggleOpen(value: DashboardOverview): void;
  dataChart: FleetsOverviewDataProps;
}

export function FleetsOverview({ toggleOpen, dataChart }: IProps) {
  const { t } = useTranslation();

  return (
    <ChartsBox
      overviewName="fleets"
      toggleOpen={toggleOpen}
      title={t('page.dashboard.fleets.title')}
    >
      <BasicHorBarChart
        title={t('page.dashboard.fleets.chart.fleetsPerSegment')}
        data={dataChart.fleetsPerSegment}
        isLoading={dataChart.isLoadingFleetsPerSegment}
        color="#90eb8d"
      />

      <BasicHorBarChart
        title={t('page.dashboard.fleets.chart.tiresPerSegment')}
        data={dataChart.tiresPerSegment}
        isLoading={dataChart.isLoadingTiresPerSegment}
        color="#e68deb"
      />

      <BasicHorBarChart
        title={t('page.dashboard.fleets.chart.tiresRunningPerFleetOnGoing')}
        data={dataChart.tiresPerFleet}
        isLoading={dataChart.isLoadingTiresPerFleet}
        color="#e68deb"
      />

      <StackedBarChart
        title={t('page.dashboard.fleets.chart.tiresPerTestTypeAndReport')}
        data={dataChart.tiresPerTestTypeAndReport}
        isLoading={dataChart.IsLoadingTiresPerTestTypeAndReport}
      />
    </ChartsBox>
  );
}
