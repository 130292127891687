import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import alertImg from '../../assets/img/alert.png';
import goodyearLogo from '../../assets/img/goodyear_blue-logo.png';
import { Button } from '../../components/Form/Button';
import { useAuth } from '../../contexts/auth';
import { Container, Title, Subtitle, Description } from './styles';

export function AccessDenied() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleRedirect = () => {
    navigate('/');
    signOut();
  };

  return (
    <Container>
      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <img
          className="goodyear-logo-img"
          src={goodyearLogo}
          alt="Goodyear Logotype"
        />

        <Title>Field Engineering Projects</Title>
      </div>

      <img className="access-denied-img" src={alertImg} alt="Access Denied" />

      <Subtitle>{t('title.accessDenied')}</Subtitle>

      <Description>{t('page.accessDenied.content.description')}</Description>

      <Button
        title={t('page.accessDenied.button.goToLogin')}
        onClick={handleRedirect}
      />
    </Container>
  );
}
