/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Form } from 'semantic-ui-react';

import { toDropdownItemProps } from '../../../../../helpers/toDropdownItemProps';
import { queryClient } from '../../../../../services/queryClient';
import {
  createTireService,
  deleteTireService,
  updateTireService,
} from '../../../../../services/tire';
import {
  createTireSizeService,
  deleteTireSizeService,
  getTireSizeService,
} from '../../../../../services/tireSizeService';
import {
  TIRESIZE_QUERYKEY,
  TIRES_QUERYKEY,
} from '../../../../../settings/constants';
import { Tire, TireFormData } from '../../../../../types/tire';
import { EditableDropdown } from '../../../EditableDropdown';
import { Table } from './components/Table';

interface ITireFormProps {
  tires?: Tire[];
}

export interface ItemsProps {
  id?: number;
  brand: string;
  model: string;
  modification: string;
  tireSize: {
    id?: number;
    label?: string;
  };
  isEdit: boolean;
}

export function TireContent({ tires }: ITireFormProps) {
  const { t } = useTranslation();
  const [tire, setTire] = useState<TireFormData>({
    brand: '',
    model: '',
    modification: '',
    tireSizeId: '',
  });

  const [items, setItems] = useState(
    tires?.map(
      (item): ItemsProps => ({
        id: item.id,
        brand: item.brand,
        model: item.model,
        modification: item.modification,
        tireSize: {
          id: item.tireSize?.id,
          label: item.tireSize?.label,
        },
        isEdit: false,
      }),
    ),
  );

  useEffect(() => {
    setItems(
      tires?.map(item => ({
        id: item.id,
        brand: item.brand,
        model: item.model,
        modification: item.modification,
        tireSize: {
          id: item.tireSize?.id,
          label: item.tireSize?.label,
        },
        isEdit: false,
      })),
    );
  }, [tires]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTire({
      ...tire,
      [e.currentTarget.name]: e.currentTarget.value.toUpperCase(),
    });
  }

  const { data: tireSizeOptions } = useQuery(
    `${TIRESIZE_QUERYKEY}_options`,
    async () => toDropdownItemProps(await getTireSizeService()),
  );

  const handleAddItem = useMutation(
    async (data: any) => {
      return createTireService(data);
    },
    {
      onSuccess: () => {
        toast.success('Item created successfully');

        if (tires) {
          setTire({
            ...tire,
            brand: '',
            model: '',
            modification: '',
            tireSizeId: '',
          });
        }

        queryClient.invalidateQueries(TIRES_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error creating item');
        }
      },
    },
  );

  const handleUpdateItem = useMutation(
    async (data: Tire) => {
      console.log('handleUpdateItem', data);

      return updateTireService(data);
    },
    {
      onSuccess: () => {
        toast.success('Item updated successfully');

        queryClient.invalidateQueries(TIRES_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('Error updating item');
        }
      },
    },
  );

  const handleDeleteItem = useMutation(
    async (id: number) => {
      await deleteTireService(id);
    },
    {
      onSuccess: () => {
        toast.success('Item deleted successfully');
        queryClient.invalidateQueries(TIRES_QUERYKEY);
      },
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(error.response?.data.error.message);
        } else {
          toast.error('This item is already in use');
        }
      },
    },
  );

  return (
    <>
      <Form>
        <Form.Group grouped>
          <Form.Group unstackable>
            <Form.Input
              label={t('component.specialEditableDropdown.tire.label.brand')}
              name="brand"
              value={tire.brand}
              onChange={e => handleChange(e)}
              width="8"
            />

            <Form.Input
              label={t('component.specialEditableDropdown.tire.label.model')}
              name="model"
              value={tire.model}
              onChange={e => handleChange(e)}
              width="8"
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              label={t(
                'component.specialEditableDropdown.tire.label.modification',
              )}
              name="modification"
              value={tire.modification}
              onChange={e => handleChange(e)}
              width="16"
            />
          </Form.Group>

          <EditableDropdown
            label={t('component.specialEditableDropdown.tire.label.size')}
            queryKey={TIRESIZE_QUERYKEY}
            options={tireSizeOptions}
            value={tire.tireSizeId}
            onChange={(_, data) =>
              setTire({
                ...tire,
                tireSizeId: data.value as number,
              })
            }
            createItemService={createTireSizeService}
            deleteItemService={deleteTireSizeService}
            activeInstructions
            instructions={
              <>
                <p>
                  <span>
                    When add new size, please, use <mark>"/"</mark> and{' '}
                    <mark>"."</mark> in the items.
                  </span>
                  <br />
                </p>
                <p>
                  Example: xxx/xxRxx.x <br />
                </p>
              </>
            }
          />

          <Form.Button
            type="button"
            content={t('component.specialEditableDropdown.button.add')}
            color="green"
            onClick={() => handleAddItem.mutateAsync(tire as any)}
            disabled={!tire.model || !tire.modification}
            fluid
          />
        </Form.Group>
      </Form>
      <Table
        tireSizeOptions={tireSizeOptions}
        items={items}
        setItems={setItems}
        handleUpdateItem={handleUpdateItem}
        handleDeleteItem={handleDeleteItem}
      />
    </>
  );
}
