import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';

import { getValuesFromArrayOfObjects } from '../../../../helpers/getValuesFromArrayOfObjects';
import { FleetVehicles } from '../../../../types/fleet';
import { Article } from '../components/Article';
import { Header } from '../components/Header';

interface IProps {
  data?: FleetVehicles;
}

export function Vehicles({ data }: IProps) {
  const { t } = useTranslation();

  return (
    <Article>
      <Header icon="truck" content="Vehicles" />

      <div className="table-container">
        <Table celled unstackable compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top" width={3}>
                {t('page.fleets.label.vehicles.quantity')}
              </Table.Cell>
              <Table.Cell className="td no-wrap" verticalAlign="top">
                {getValuesFromArrayOfObjects(data?.numberVehicles)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.vehicles.brand')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {getValuesFromArrayOfObjects(data?.truckBrands)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.vehicles.engineTypes')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top">
                {getValuesFromArrayOfObjects(data?.engineTypes)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.vehicles.tractionConfiguration')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="3">
                {getValuesFromArrayOfObjects(data?.axles)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.vehicles.trailerConfiguration')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="3">
                {getValuesFromArrayOfObjects(data?.trucks)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.vehicles.totalGrossWheightKg')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="3">
                {data?.totalGrossWeight}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="th" verticalAlign="top">
                {t('page.fleets.label.vehicles.vehiclesCategory')}
              </Table.Cell>
              <Table.Cell className="td" verticalAlign="top" colSpan="3">
                {getValuesFromArrayOfObjects(data?.truckModels)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Article>
  );
}
