import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { createFleetService } from '../../../services/fleets';
import { queryClient } from '../../../services/queryClient';
import { FleetDataCategorized } from '../../../types/fleet';

export function useCreateFleet(onRequestClose: () => void) {
  const { t } = useTranslation();

  const errorMessages: any = {
    'This Fleet already exists': t('page.fleets.error.thisFleetAlreadyExists'),
  };

  return useMutation(
    async (data: FleetDataCategorized) => {
      return createFleetService(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fleets');
        onRequestClose();
        toast.success('Fleet created successfully');
      },
      onError: (error: AxiosError<ErrorEvent>) => {
        toast.error(
          errorMessages[error.response?.data.error.message] ||
            'Error creating fleet',
        );
      },
    },
  );
}
