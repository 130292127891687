import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { UseMutationResult } from 'react-query';
import {
  Accordion,
  DropdownItemProps,
  Form,
  Icon,
  Input,
  Segment,
} from 'semantic-ui-react';

import ControllerDropdown from '../../../../../../components/Form/ControllerDropdown';
import { ControllerFormDropdown } from '../../../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../../../components/Form/ControllerFormField';
import { ControllerFormFieldInput } from '../../../../../../components/Form/ControllerFormFieldInput';
import { ControllerFormInputFile } from '../../../../../../components/Form/ControllerFormInputFile';

interface IProps {
  index: number;

  isActiveAccordion: boolean;
  setIsActiveAccordion: React.Dispatch<React.SetStateAction<boolean>>;

  currentRouteFiles: File[];
  setCurrentRouteFiles: React.Dispatch<React.SetStateAction<File[]>>;

  originState?: DropdownItemProps[];
  originCity?: DropdownItemProps[];

  destinationState?: DropdownItemProps[];
  destinationCity?: DropdownItemProps[];

  handleDeleteFile: UseMutationResult<void, any, void, unknown>;

  onSubmit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<any>;
}

export function AccordionContent({
  index,

  isActiveAccordion,
  setIsActiveAccordion,

  currentRouteFiles,
  setCurrentRouteFiles,

  originState,
  originCity,

  destinationState,
  destinationCity,

  handleDeleteFile,

  onSubmit,
}: IProps) {
  const { control, setValue } = useFormContext();

  const watchRouteFileName = useWatch({
    control,
    name: `routesAndControl.route.${index}.fileRoute`,
  });

  function onChangeFile(file?: File) {
    const blob = file?.slice(0, file?.size, 'image/png');

    const date = new Date();

    const CURRENT_DATE_TIME = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;

    const newFile = new File(
      [blob as Blob],
      `${file?.name
        .split('.')[0]
        .replace(/ /g, '_')}_${CURRENT_DATE_TIME}.${file?.name.split('.')[1]}`,
    );

    if (file) {
      const currentRouteFilesAux = [...currentRouteFiles];
      currentRouteFilesAux[index] = newFile;
      setCurrentRouteFiles(currentRouteFilesAux);

      setValue(`routesAndControl.route.${index}.fileRoute`, newFile.name);
    }
  }

  return (
    <Accordion.Content active={isActiveAccordion}>
      <Form.Field label="Origin / Destination" />

      <Form.Group className="routes__origin-destination" grouped>
        <Form.Group unstackable widths="3" inline>
          <span>
            <Icon name="dot circle outline" />
            <div className="routes__3dots" />
          </span>

          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              gap: '10px',
            }}
          >
            <ControllerFormDropdown
              controllerControl={control}
              controllerName={`routesAndControl.route.${index}.originRegion`}
              options={originState ?? []}
              compact
              width="4"
            />

            <ControllerDropdown
              controllerName={`routesAndControl.route.${index}.originCity`}
              options={originCity ?? []}
              fluid
              disabled={!originCity}
            />
          </span>
        </Form.Group>

        <Form.Group unstackable widths="3" inline>
          <span>
            <Icon name="map marker alternate" />
          </span>

          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              gap: '10px',
            }}
          >
            <ControllerFormDropdown
              controllerControl={control}
              controllerName={`routesAndControl.route.${index}.destinationRegion`}
              options={destinationState ?? []}
              compact
              width="4"
            />

            <ControllerDropdown
              controllerName={`routesAndControl.route.${index}.destinationCity`}
              options={destinationCity ?? []}
              fluid
              disabled={!destinationCity}
            />
          </span>
        </Form.Group>
      </Form.Group>

      <ControllerFormField
        maxLength={200}
        controllerControl={control}
        controllerName={`routesAndControl.route.${index}.productLoades`}
        control={Input}
        label="Product loades"
        size="small"
      />

      <ControllerFormField
        controllerControl={control}
        controllerName={`routesAndControl.route.${index}.qtyVehicles`}
        control={Input}
        type="number"
        label="Quantity vehicles"
        size="small"
      />

      <Form.Field label="Vbox analysis" />
      <Form.Group grouped>
        <ControllerFormFieldInput
          controllerControll={control}
          controllerName={`routesAndControl.route.${index}.rsnX`}
          label="RSN X"
        />
        <ControllerFormFieldInput
          controllerControll={control}
          controllerName={`routesAndControl.route.${index}.rsnY`}
          label="RSN Y"
        />
        <ControllerFormFieldInput
          controllerControll={control}
          controllerName={`routesAndControl.route.${index}.rsnT`}
          label="RSN T"
        />
      </Form.Group>

      <Form.Group grouped as={Segment} basic>
        <ControllerFormInputFile
          control={control}
          label="Attach File"
          watchFileName={watchRouteFileName}
          onChangeFile={onChangeFile}
          onDeleteFile={() => handleDeleteFile.mutateAsync()}
        />

        <Form.Button basic compact size="tiny" onClick={e => onSubmit(e)}>
          Upload File
        </Form.Button>
      </Form.Group>

      <Form.Button
        type="button"
        content="Done"
        color="green"
        onClick={() => setIsActiveAccordion(false)}
        fluid
      />
    </Accordion.Content>
  );
}
