import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DropdownItemProps } from 'semantic-ui-react';

import { toDropdownItemProps } from '../../../helpers/toDropdownItemProps';
import { getFleetListService } from '../../../services/fleets';
import { getFleetTypeService } from '../../../services/fleetTypeService';
import { getProjectListService } from '../../../services/project';
import {
  getScheduleService,
  getScheduleTaskPrioritiesService,
  getScheduleTaskStatusService,
} from '../../../services/schedule';
import { getUserListService } from '../../../services/user';
import { FLEET_OPTIONS_QUERYKEY } from '../../../settings/constants';
import { FilterParamsProps } from '../../../types/schedule';

export function useScheduleFilterFormResquests(
  filterParams: FilterParamsProps,
) {
  const { data: businessOptions, isLoading: isLoadingBusinessOptions } =
    useQuery('SCHEDULE_BUSINESS_QUERYKEY', async () =>
      toDropdownItemProps(await getFleetTypeService()),
    );

  const { data: scheduleData, isLoading: isLoadingScheduleData } = useQuery(
    'scheduleData',
    getScheduleService,
  );

  const taskTypeOptions = toDropdownItemProps(scheduleData?.datasets);

  const taskNameOptions = useMemo(() => {
    const taskList = scheduleData?.datasets?.find(
      ({ id }) => id === filterParams.selectedTaskTypeId,
    );

    return toDropdownItemProps(taskList?.values);
  }, [filterParams.selectedTaskTypeId, scheduleData?.datasets]);

  const { data: priorityOptions, isLoading: isLoadingPriorityOptions } =
    useQuery('PRIORITIES_QUERYKEY', async () =>
      toDropdownItemProps(await getScheduleTaskPrioritiesService(), true),
    );

  const { data: statusOptions, isLoading: isLoadingStatusOptions } = useQuery(
    'SCHEDULE_TASK_STATUS_QUERYKEY',
    async () => toDropdownItemProps(await getScheduleTaskStatusService(), true),
  );

  const { data: projectOptions, isLoading: isLoadingProjectOptions } = useQuery(
    'PROJECT_OPTIONS_QUERYKEY',
    async () => {
      const data = await getProjectListService({
        page: 0,
        size: 1000,
      });

      return toDropdownItemProps(data?._embedded.projects);
    },
  );

  const { data: fleetOptions, isLoading: isLoadingFleetOptions } = useQuery(
    FLEET_OPTIONS_QUERYKEY,
    async () => {
      const data = await getFleetListService({
        page: 0,
        size: 1000,
      });

      return toDropdownItemProps(data?._embedded.fleets);
    },
  );

  const { data: usersOptions, isLoading: isUsersLoading } = useQuery(
    'USERS_2',
    async () => {
      const res = await getUserListService();

      return res.map(
        ({ email, id, name }): DropdownItemProps => ({
          key: id,
          text: name,
          value: email.toLowerCase(),
        }),
      );
    },
  );

  return {
    businessOptions,
    isLoadingBusinessOptions,

    taskTypeOptions,
    taskNameOptions,
    isLoadingScheduleData,

    priorityOptions,
    isLoadingPriorityOptions,

    statusOptions,
    isLoadingStatusOptions,

    projectOptions,
    isLoadingProjectOptions,

    fleetOptions,
    isLoadingFleetOptions,

    usersOptions,
    isUsersLoading,
  };
}
