import { useTranslation } from 'react-i18next';

import { DashboardOverview } from '../..';
import { backgroundAnalogousColors } from '../../../../settings/constants';
import { ScheduleOverviewDataProps } from '../../../../types/dashboard';
import { getHexadecimalColor } from '../../../../utils/getHexadecimalColor';
import { getSemanticColor } from '../../../../utils/getSemanticColor';
import { DoughnutChart } from '../Charts';
import { BasicHorBarChart } from '../Charts/BasicHorBarChart';
import { StackedBarChart } from '../Charts/StackedBarChart';
import { ChartsBox } from '../ChartsBox';

interface IProps {
  toggleOpen(value: DashboardOverview): void;
  dataChart: ScheduleOverviewDataProps;
}

export function ScheduleOverview({ toggleOpen, dataChart }: IProps) {
  const { t } = useTranslation();

  const schedulePerPriorityBgcolor = dataChart.schedulePerPriority?.labels?.map(
    label => getHexadecimalColor(label),
  );

  const schedulePerStatusBgcolor = dataChart.schedulePerStatus?.labels?.map(
    label => getSemanticColor(label),
  );

  return (
    <ChartsBox
      overviewName="schedule"
      toggleOpen={toggleOpen}
      title={t('page.dashboard.scheduleTasks.title')}
    >
      <DoughnutChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerBusiness')}
        data={dataChart.schedulePerBusiness}
        isLoading={dataChart.isLoadingSchedulePerBusiness}
        backgroundColor={backgroundAnalogousColors}
      />

      <BasicHorBarChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerType')}
        data={dataChart.schedulePerTaskType}
        isLoading={dataChart.isLoadingSchedulePerTaskType}
      />

      <BasicHorBarChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerName')}
        data={dataChart.schedulePerTask}
        isLoading={dataChart.isLoadingSchedulePerTask}
      />

      <DoughnutChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerPriority')}
        data={dataChart.schedulePerPriority}
        isLoading={dataChart.isLoadingSchedulePerPriority}
        backgroundColor={schedulePerPriorityBgcolor}
      />

      <DoughnutChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerStatus')}
        data={dataChart.schedulePerStatus}
        isLoading={dataChart.isLoadingSchedulePerStatus}
        backgroundColor={schedulePerStatusBgcolor}
      />

      <BasicHorBarChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerProject')}
        data={dataChart.schedulePerProject}
        isLoading={dataChart.isLoadingSchedulePerProject}
      />

      <BasicHorBarChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerFleet')}
        data={dataChart.schedulePerFleet}
        isLoading={dataChart.isLoadingSchedulePerFleet}
      />

      <BasicHorBarChart
        title={t('page.dashboard.scheduleTasks.chart.tasksPerResponsible')}
        data={dataChart.schedulePerResponsible}
        isLoading={dataChart.isLoadingSchedulePerResponsible}
      />

      <StackedBarChart
        title={t(
          'page.dashboard.scheduleTasks.chart.tasksPerStatusAndEngineer',
        )}
        data={dataChart.schedulePerStatusAndEngineer}
        isLoading={dataChart.isLoadingSchedulePerStatusAndEngineer}
      />
    </ChartsBox>
  );
}
