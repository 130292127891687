import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body, input, a, button, h1, h2, h3, h4, h5, h6 {
        font-family: 'Barlow', Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    body {
        .page__title {
            font-size: 2rem;
            font-weight: bold;
            line-height: 1em;
            
            transition: all ease .3s;
        }
    }
    

    /* REFACTORAR EM BREVE ↓ */
    body {
        background-color: ${({ theme }) => theme.colors.background};
        
        .page__title {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1em;

      transition: all ease .3s;
    }

    .table__scrollable {
      max-height: 65vh;
      max-width: 100%;

      overflow: auto;

      &::-webkit-scrollbar-track {
          background: none;
      };
      &::-webkit-scrollbar {
          width: 4px;
          scroll-padding-top: 100px;
      };

      .table {
        outline: 0;
        border: 0;
        border-radius: 5px;
        border-collapse: separate;

        border-radius: 5px;

        flex: 1;

        a {
          font-size: 1rem;
        }

        .thead {
          background-color:  ${props => props.theme.colors.gray};
          position: sticky;
          top: 0;

          z-index: 1;
          tr {
            .th {
              background: none;
              color: #FFF;
              font-weight: 600;
            }
            .action{
              background-color:  ${props => props.theme.colors.gray};;
            }
          }
        }

        .tbody {
          color: ${props => props.theme.colors.text};

          &:nth-child(odd) {
            background-color: #F2F2F2;
          }
          &:hover td[rowspan], tr:hover td {
            background-color: #E1E1E1;
          }

          .noRowspan {
            &:nth-child(even) {
              background-color: #f2f2f2;
            }
          }

          .tr {
            height: 5rem;

            .rowspan {
              &:nth-child(even)[rowspan] {
                background-color: #f2f2f2;
              }
            }
            .td {
              .labels {
                white-space: normal;
                .label {
                  white-space: nowrap;
                }
              }

            }
            .action {
              background-color: #FFF;
              outline: 1px solid #F2F2F2;
              box-shadow: -2px 2px 8px -2px #33333370 !important;
              i {
                cursor: pointer;
              }
              .action-buttons {
                padding: 4px;
                display: flex;
                flex-direction: row;
                gap: 4px !important;
              }
              span > .dropdown {
                display: flex;
                justify-content: center;
                gap: 8px;
                position: sticky;
              }
            }
            .table-description {
                max-width: 600px;
            }

          }
        }
        .th, .td {
          padding: 8px 16px;
          border: 0;
          white-space: nowrap;
        }
        .id {
          min-width: 30px;
          max-width: 40px;
          padding: 10px 8px;
          text-align: center;
        }
        .action {
          min-width: 40px;
          max-width: 40px;
          padding: 10px 8px;
          text-align: center;
          box-shadow: -4px 0 4px #33333320;
          position: sticky;
          right: 0;
        }
        .table-description {
          max-width: 480px;
          white-space: pre-wrap;
        }
      }
    }

    .table__pagination {
      background-color: #FFFFFF;
      padding: 10px 8px;
      border-radius: 5px
    }

    @media (max-width: 460px) {

      .page__title {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .table__scrollable {

        .table {
          font-size: 1rem;

          .tbody {
            .tr {
              height: auto;
            }
          }
        }
      }
      .table__pagination {
        height: 56px;
        width: 100%;
        background-color: #FFFFFF;
        padding: 10px 8px;
        border-radius: 5px;

        position: fixed;
        bottom: 0;
      }
    }

    @media (max-height: 1024px) {
      .table__scrollable {
        max-height: 70vh;
      }
    }

    @media (max-height: 980px) {
      .table__scrollable {
        max-height: 67vh;
      }
    }

    @media (max-height: 895px) {
      .table__scrollable {
        max-height: 65vh;
      }
    }

    @media (max-height: 765px) {
      .table__scrollable {
        max-height: 60vh;
      }
    }

    @media (max-height: 720px) {
      .table__scrollable {
        max-height: 58vh;
      }
    }

    @media (max-height: 630px) {
      .table__scrollable {
        max-height: 55vh;
      }
    }

    @media (max-height: 565px) {
      .table__scrollable {
        max-height: 50vh;
      }
    }

    @media (max-height: 510px) {
      .table__scrollable {
        max-height: 45vh;
      }
    }
    }
`;
