import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlinePivotTableChart } from 'react-icons/md';
import { Button, Icon, Popup } from 'semantic-ui-react';

import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import { usePermission } from '../../hooks/usePermission';
import { ButtonGDY } from '../Form/ButtonGDY';
import FilterBar from './components/FilterBar';
import { Container } from './styles';

type ToolbarPagesProps = {
  onAddClick?: () => void;
  onHolidayClick?: () => void;
  children?: React.ReactNode;
  onApplyClick(): void;

  disableNewButton?: boolean;
  enableHolidayButton?: boolean;
  enableExportFileButton?: boolean;
  enableToggleTables?: boolean;
  enableShowHideButton?: boolean;
  enableTireDetailsButton?: boolean;
  enableFilterButton?: boolean;

  showTireDetailsBar?: boolean;
  setShowTireDetailsBar?: React.Dispatch<React.SetStateAction<boolean>>;

  toggleSearchPageTable?: boolean;

  handleToggleTablesSearch?: () => void;
  handleShowHideModalOpen?: () => void;
  handleDownloadExcel?: () => void;
};

export function Toolbar({
  onAddClick,
  onHolidayClick,
  children,
  onApplyClick,

  disableNewButton,
  enableHolidayButton,
  enableExportFileButton,
  enableToggleTables,
  enableShowHideButton,
  enableTireDetailsButton,
  enableFilterButton = true,

  showTireDetailsBar,
  setShowTireDetailsBar,

  toggleSearchPageTable,

  handleToggleTablesSearch,
  handleShowHideModalOpen,
  handleDownloadExcel,
}: ToolbarPagesProps) {
  const { t } = useTranslation();

  const { isAdmin, isEditor } = usePermission();
  const { isMobileScreen } = useIsMobileScreen(460);

  const [showFilterBar, setShowFilterBar] = useState<boolean>(false);

  const toggleTireDetailsBar = () => {
    if (setShowTireDetailsBar) {
      setShowTireDetailsBar(oldState => !oldState);
    }
  };

  const toggleFilterBar = () => {
    setShowFilterBar(prevState => !prevState);
  };

  function closeFilterBar() {
    setShowFilterBar(false);
  }

  return (
    <>
      <Container>
        <div className="content">
          <span>
            {isEditor && !disableNewButton && (
              <ButtonGDY
                className="add-button"
                icon
                labelPosition="left"
                onClick={onAddClick}
              >
                <Icon name="add" />
                {t('toolbar.button.new')}
              </ButtonGDY>
            )}

            {isAdmin && enableHolidayButton && (
              <ButtonGDY
                className="add-button"
                icon
                labelPosition="left"
                onClick={onHolidayClick}
              >
                <Icon name="calendar alternate" />
                {t('toolbar.button.holidays')}
              </ButtonGDY>
            )}
          </span>

          <div style={{ marginLeft: 'auto' }}>
            <div>
              <Button.Group>
                {enableTireDetailsButton && (
                  <Popup
                    disabled={isMobileScreen}
                    content="Charts"
                    trigger={
                      <ButtonGDY
                        icon="chart bar"
                        active={showTireDetailsBar}
                        onClick={toggleTireDetailsBar}
                      />
                    }
                  />
                )}

                {enableExportFileButton && (
                  <Popup
                    disabled={isMobileScreen}
                    content="Download the spreadsheet in excel format (.xlsx)"
                    trigger={
                      <ButtonGDY
                        icon="download"
                        onClick={handleDownloadExcel}
                      />
                    }
                  />
                )}

                {enableToggleTables && (
                  <Popup
                    disabled={isMobileScreen}
                    content="Switch between tables"
                    trigger={
                      <ButtonGDY
                        icon={<MdOutlinePivotTableChart fontSize={16} />}
                        active={toggleSearchPageTable}
                        onClick={handleToggleTablesSearch}
                      />
                    }
                  />
                )}

                {enableShowHideButton && (
                  <Popup
                    disabled={isMobileScreen}
                    content="Customize the display of table columns"
                    trigger={
                      <ButtonGDY icon="cog" onClick={handleShowHideModalOpen} />
                    }
                  />
                )}

                {enableFilterButton && (
                  <Popup
                    disabled={isMobileScreen}
                    content="Filter"
                    trigger={
                      <ButtonGDY
                        className="filter-list"
                        icon="filter"
                        active={showFilterBar}
                        onClick={toggleFilterBar}
                      />
                    }
                  />
                )}
              </Button.Group>
            </div>
          </div>
        </div>
      </Container>

      <FilterBar
        onApplyClick={onApplyClick}
        closeFilterBar={closeFilterBar}
        show={showFilterBar}
      >
        {children}
      </FilterBar>
    </>
  );
}
