import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import {
  Table as TableSUI,
  DropdownItemProps,
  Dropdown,
  Input,
  Icon,
} from 'semantic-ui-react';

import { ItemsProps } from '../..';
import { TireFormData } from '../../../../../../../types/tire';
import { handleChangeLabelItem } from '../../../../utils/handleChangeLabelItem';
import { handleChangeOptionItem } from '../../utils/handleChangeOptionItem';
import { handleClickCheckItem } from '../../utils/handleClickCheckItem';
import { handleClickEditItem } from '../../utils/handleClickEditItem';

interface Props {
  tireSizeOptions?: DropdownItemProps[];
  items?: ItemsProps[];
  setItems: React.Dispatch<React.SetStateAction<ItemsProps[] | undefined>>;
  handleUpdateItem: UseMutationResult<TireFormData, unknown, any, unknown>;
  handleDeleteItem: UseMutationResult<void, unknown, number, unknown>;
}

export function Table({
  tireSizeOptions,
  items,
  setItems,
  handleUpdateItem,
  handleDeleteItem,
}: Props) {
  const { t } = useTranslation();

  return (
    <TableSUI compact striped unstackable>
      <TableSUI.Header>
        <TableSUI.Row>
          <TableSUI.HeaderCell>
            {t('component.specialEditableDropdown.tire.label.brand')}
          </TableSUI.HeaderCell>
          <TableSUI.HeaderCell>
            {t('component.specialEditableDropdown.tire.label.model')}
          </TableSUI.HeaderCell>
          <TableSUI.HeaderCell>
            {t('component.specialEditableDropdown.tire.label.modification')}
          </TableSUI.HeaderCell>
          <TableSUI.HeaderCell colSpan="4">
            {t('component.specialEditableDropdown.tire.label.size')}
          </TableSUI.HeaderCell>
        </TableSUI.Row>
      </TableSUI.Header>
      <TableSUI.Body>
        {items?.map(
          ({
            id,
            brand,
            model,
            modification,
            tireSize: { id: tireSizeId, label: tireSizeLabel },
            isEdit,
          }) => (
            <TableSUI.Row key={`${id}`}>
              <TableSUI.Cell>
                {isEdit ? (
                  <Input
                    name="brand"
                    value={brand}
                    onChange={(e, { value }) =>
                      handleChangeLabelItem({ items, setItems, id, e, value })
                    }
                  />
                ) : (
                  brand
                )}
              </TableSUI.Cell>
              <TableSUI.Cell>
                {isEdit ? (
                  <Input
                    name="model"
                    value={model}
                    onChange={(e, { value }) =>
                      handleChangeLabelItem({ items, setItems, id, e, value })
                    }
                  />
                ) : (
                  model
                )}
              </TableSUI.Cell>
              <TableSUI.Cell>
                {isEdit ? (
                  <Input
                    name="modification"
                    value={modification}
                    onChange={(e, { value }) =>
                      handleChangeLabelItem({ items, setItems, id, e, value })
                    }
                  />
                ) : (
                  modification
                )}
              </TableSUI.Cell>
              <TableSUI.Cell>
                {isEdit ? (
                  <Dropdown
                    compact
                    search
                    clearable
                    selection
                    name="tireSizeId"
                    options={tireSizeOptions}
                    value={tireSizeId}
                    onChange={(e, { value }) =>
                      handleChangeOptionItem({ items, setItems, id, e, value })
                    }
                  />
                ) : (
                  tireSizeLabel
                )}
              </TableSUI.Cell>

              {isEdit ? (
                <TableSUI.Cell width="1">
                  <Icon
                    link
                    name="check"
                    color="green"
                    onClick={() =>
                      handleClickCheckItem({
                        items,
                        setItems,
                        id,
                        handleUpdateItem,
                      })
                    }
                  />
                </TableSUI.Cell>
              ) : (
                <TableSUI.Cell width="1" />
              )}
              <TableSUI.Cell width="1">
                <Icon
                  link
                  name="pencil"
                  color={isEdit ? undefined : 'yellow'}
                  onClick={() =>
                    handleClickEditItem({
                      items,
                      setItems,
                      id,
                    })
                  }
                  disabled={isEdit}
                />
              </TableSUI.Cell>
              <TableSUI.Cell width="1">
                <Icon
                  link
                  name="trash"
                  color={isEdit ? undefined : 'red'}
                  onClick={() => handleDeleteItem.mutateAsync(id as number)}
                  disabled={isEdit}
                />
              </TableSUI.Cell>
            </TableSUI.Row>
          ),
        )}
      </TableSUI.Body>
    </TableSUI>
  );
}
